  import React, { useState,useEffect } from "react";
  import Highcharts from "highcharts";
  import HighchartsReact from "highcharts-react-official";
  import { GRAPH, LINK_ICON } from "../../utlis/assests";
  import { countryData ,countryDataChange} from "../../utlis/helper";
  import CountryCard from "./CountryCard";
  import classes from "./card.module.css";
  import "./chart.css";
  import "./ProductCard.css";
  import SmallChart from "../smallChart/SmallChart";
  import { useAuth } from "../../context/DataContext";
  const ProductCard = ({ product ,name}) => {
    const[auth]=useAuth();
    const[popup,setPopup]=useState(false);
    const [active,setActive]=useState("1")
    const [managed,setManaged]=useState(1)
    const handleSetActive = (value) => {
      setActive(value);
    };
    function popups(){
  setPopup(true);
    }
    useEffect(() => {
      if (!auth) {
        setManaged(1);
      } else if (auth === 2 || auth === 3 ||  auth===4|| auth===1) {
      //   console.log(auth, "--", managed);
        setManaged(auth);
      }
    }, [auth]);
    return (
      <>
        <div className="column-chart-inner">
          <div className="flex gap-2.5 justify-between w-full text-lg leading-4 text-center whitespace-nowrap  text-stone-950">
            <div className="flex gap-1">
              <h3 className="sfiziaitalic">{name}</h3>
            </div>
            <div  onClick={popups} style={{cursor:"pointer"}}>

            
            <img
              loading="lazy"
              src={LINK_ICON}
              alt=""
              
              className="shrink-0 aspect-[1.32] w-[21px]"
            />
            </div>
          </div>
              {popup&&(<>
                <>
                <div className="countries-table-pop">
                <div className="table-pop-up">
                  <div className="head">
                    <div className="left-block">
                      <div className="title">Countries</div>
                      <div>Valeurs absolues</div>
                      <div class="checkbox-box">
                        <input className="tgl tgl-light" id="cb1-7" type="checkbox" />
                        <label className="tgl-btn" for="cb1-7">
                        </label>
                        <span className="light-color">Variations des valeurs</span>
                      </div>
                    </div>
                    <div className="close-btn" onClick={(e) => setPopup(false)}></div>
                  </div>
 
                  <div className="table-data-box">
                    <table width="100%" cellPadding={0} cellSpacing={0}>
                      <tr>
                        <th />
                        <th colSpan={4}>Revenue</th>
                        <th colSpan={4}>ADR</th>
                        <th colSpan={4}>Room nights</th>
                      </tr>
                      <tr>
                        <td>Country <br /><input type="search" /></td>
                        <td>Selected period</td>
                        <td>STLY</td>
                        <td>Pick-up 7 days</td>
                        <td>Pick-up STLY</td>
                        <td>Selected period</td>
                        <td>STLY</td>
                        <td>Pick-up 7 days</td>
                        <td>Pick-up STLY</td>
                        <td>Selected period</td>
                        <td>STLY</td>
                        <td>Pick-up 7 days</td>
                        <td>Pick-up STLY</td>
                      </tr>
                      <tr>
                        <td>France</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                      </tr>
                      <tr>
                        <td>Germany</td>
                        <td>$ 3.5 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 3.5 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 3.5 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                      </tr>
                      <tr>
                        <td>Danemark</td>
                        <td>$ 3.3 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 3.3 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 3.3 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                      </tr>
                      <tr>
                        <td>Switzerland</td>
                        <td>$ 3.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 3.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 3.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                      </tr>
                      <tr>
                        <td>USA</td>
                        <td>$ 2.7 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 2.7 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 2.7 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                      </tr>
                      <tr>
                        <td>Lorem Ipsum</td>
                        <td>$ 2.6 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 2.6 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 2.6 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                      </tr>
                      <tr>
                        <td>Lorem Ipsum</td>
                        <td>$ 2.6 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 2.6 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 2.6 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                      </tr>
                      <tr>
                        <td>Lorem Ipsum</td>
                        <td>$ 2.6 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 2.6 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 2.6 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                        <td>$ 4.0 M</td>
                      </tr>
                    </table>
                  </div>
 
 
 
 
                </div>
              </div>
  </>

              </>)}

          {/* <ChartComponent className="chartContainer " dataArray={countryData} /> */}
          {product === managed ?
          <SmallChart className="chartContainer " dataArray={countryDataChange} />
          :
          <SmallChart className="chartContainer " dataArray={countryData} />
          }
          <h4 className="sfiziaitalic">Pick-Up</h4>
     
           {product === managed ? (
        countryDataChange.map((country) => (
          <CountryCard key={country.id} {...country} />
        ))
      ) : (
        countryData.map((country) => (
          <CountryCard key={country.id} {...country} />
        ))
      )}
        </div>
      </>
    );
  };

  export default ProductCard;
