import { CHECK_ICON } from "./assests";

export const MetricData = [
  {  id: 1,
    value: "9.3",
    lastYear: "3.9 M",
    total: "+100",
    profit: "30%",
    budget: "3.9 M",
    forecast: "3.9 M",
    change: "3.9 M",
    title: "Total Revenue",
    change: "+116",
  
  },
  { id: 2,
    value: "138",
    lastYear: "3.9 M",
    total: "-100",
    profit: "30%",
    budget: "3.9 M",
    forecast: "3.9 M",
   
    title: "ADR",
    change: "-116",
  
  },
  { id: 3,
    value: "140",
    lastYear: "3.9 M",
    total: "+100",
    profit: "30%",
    budget: "3.9 M",
    forecast: "3.9 M",
  
    title: "Occupancy Rates",
    change: "+120",
  
  },
    {
      id: 4,
    value: "145",
    lastYear: "3.9 M",
    total: "+100",
    profit: "30%",
    budget: "3.9 M",
    forecast: "3.9 M",
   
    title: "RevPAR",
    change: "+116",
  
  },
];
