import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faFilter,
  faChevronRight,
  faChevronLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";
// import RangeInputCalendar from "./RangeInput";
import RangeInputCompair from "./RangeInputCompair";
import RangeCompairSelect from "./RangeCopairSelect";

const CustomCalendarCompair = ({ isActiv, closePopup2, ChildToParentComapir }) => {
  const today = new Date();
  const year=today.getFullYear();
  
  const quarters = [
    { quarter: 1, start: new Date(year, 0, 1), end: new Date(year, 2, 31) },
    { quarter: 2, start: new Date(year, 3, 1), end: new Date(year, 5, 30) },
    { quarter: 3, start: new Date(year, 6, 1), end: new Date(year, 8, 30) },
    { quarter: 4, start: new Date(year, 9, 1), end: new Date(year, 11, 31) }
];

  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
  const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
  const firstDayOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
  const lastDayOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth()-1, 1);
  const lastDayOfLastMonth=new Date(today.getFullYear(),today.getMonth()-1,today.getDate());
  const thismonth=new Date(today.getFullYear(),today.getMonth(),1);
  const lastdayofLAstMonth=new Date(thismonth-1);
  const [dateRang, setDateRang] = useState([firstDayOfMonth, lastdayofLAstMonth]);
  const [startingDat, setStartingDat] = useState(firstDayOfMonth);
  const [endingDat, setEndingDat] = useState(lastdayofLAstMonth);
  const [selectFilte, setSelectFilte] = useState("Month This");
  const [isActive11, setIsActive11] = useState(false);
  const [isActive21, setIsActive21] = useState(false);
  const [isActive31, setIsActive31] = useState(false);
  const [isActive41, setIsActive41] = useState(false);
  const [isDateRangeInitialize, setIsDateRangeInitialize] = useState(false);
  function getQuarter(date) {
    const month = date.getMonth();
    return Math.floor(month / 3) + 1;
}

  const submitFunctionCompair=()=>{
    ChildToParentComapir(dateRang);
    closePopup2();
  }
  const childToParent = (date) => {
    console.log("funttion called ",date)
    setIsActive41(!isActive41);
    // ChildToParentComapir(dateRang);
    setSelectFilte("customers");
    setDateRang([date,null])
  };

  const changedmonth = () => {
    setSelectFilte("Month This");
    setDateRang([firstDayOfMonth, lastdayofLAstMonth])
    ChildToParentComapir(dateRang)
  };
  const changedquater = () => {
    setSelectFilte("Quater");
    const currentQuarter = getQuarter(today);
    // console.log("")
    quarters.forEach(ele=>{
      if(ele.quarter === currentQuarter){
      setDateRang([ele.start,ele.end])
      }
    })
    ChildToParentComapir(dateRang)
    // setDateRang([threeMonthsAgo,lastDayOfMonth])
  };
  const changedyear = () => {
    setSelectFilte("Year");
    setDateRang([firstDayOfLastYear,lastDayOfLastYear])
    ChildToParentComapir(dateRang);
  };
  const changedmonthtodate = () => {
    setSelectFilte("MonthtoDate");
    setDateRang([thismonth, today])
    ChildToParentComapir(dateRang);
  };
  const changedyeardate = () => {
    setSelectFilte("YeartoDate");
    setDateRang([ new Date(today.getFullYear(),0,1) , today])
    ChildToParentComapir(dateRang);
  };

  const handleToggle1 = () => {
    setIsActive11(!isActive11);
  };
  const handleToggle2 = () => {
    setIsActive21(!isActive21);
  };
  const handleToggle3 = () => {
    setIsActive31(!isActive31);
  };
  const handleToggle4 = () => {
    setIsActive41(!isActive41);
    ChildToParentComapir(dateRang);
    setSelectFilte("customers");
    
  };

  const handleDateChange = (setter, nu) => (event) => {
    try {
      const dateValue = event.target.value;
      if (!dateValue) {
        setter(null); 
        return;
      }
      
      const parsedDate = new Date(dateValue);
      if (isNaN(parsedDate.getTime())) {
        throw new Error('Invalid Date');
      }
      if (nu === 1) {
        setStartingDat(new Date(parsedDate));
      }
      if (nu === 2) {
        setEndingDat(new Date(parsedDate));
      }
      
        setter(parsedDate);

    } catch (error) {
      console.log("ivalid end date ")
    }
   
  };

  useEffect(() => {
    setDateRang([startingDat, endingDat]);
    if (startingDat && endingDat) {
      setIsDateRangeInitialize(true);
    }
  }, [startingDat, endingDat]);

  useEffect(() => {
    setDateRang([firstDayOfMonth, endingDat]);
    setIsDateRangeInitialize(true);
  }, []);

  if (!isActiv) return null;

  return (
    <div className="calendar-container">
      {/* {day} */}
      <div className="close-btn" onClick={closePopup2}></div>

      <div className="filters">
        <div className="filter-title">
          <FontAwesomeIcon icon={faFilter} /> Filters
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="Month This">
              <label htmlFor="thisMoneths" className="checkbox">
                Last month
                <input
                  type="radio"
                  id="thisMoneths"
                  name="filter"
                  checked={selectFilte === "Month This"}
                  onChange={changedmonth}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="Quater">
              <label htmlFor="quaterr" className="checkbox">
                Last quarter
                <input
                  type="radio"
                  id="quaterr"
                  name="filter"
                  checked={selectFilte === "Quater"}
                  onChange={changedquater}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="Year">
              <label htmlFor="Year" className="checkbox">
                Last year
                <input
                  type="radio"
                  id="Year"
                  name="filter"
                  checked={selectFilte === "Year"}
                  onChange={changedyear}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="MonthtoDate">
              <label htmlFor="monthtoda" className="checkbox">
                Month to date
                <input
                  type="radio"
                  id="monthtoda"
                  name="filter"
                  checked={selectFilte === "MonthtoDate"}
                  onChange={changedmonthtodate}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="YeartoDate">
              <label htmlFor="YeartoDate" className="checkbox">
                Year to date
                <input
                  type="radio"
                  id="YeartoDate"
                  name="filter"
                  checked={selectFilte === "YeartoDate"}
                  onChange={changedyeardate}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className={`toggle-box ${isActive11 ? "active" : ""}`}>
          <div className="title-box" onClick={handleToggle1}>
            <div className="filter-title">Events</div>
            <span className="toggle-btn">
              <FontAwesomeIcon icon={isActive11 ? faAngleUp : faAngleDown} />
            </span>
          </div>
          <div className="filter-group">
            <div className="sort-by-filter">
              <span className="option" value="Events">
                <label htmlFor="event11" className="checkbox">
                  Events
                  <input type="radio" id="event11" name="event" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>

            <div className="sort-by-filter">
              <span className="option" value="Events">
                <label htmlFor="event12" className="checkbox">
                  Events
                  <input type="radio" id="event12" name="event" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>
          </div>
        </div>

        <div className={`toggle-box ${isActive21 ? "active" : ""}`}>
          <div className="title-box" onClick={handleToggle2}>
            <div className="filter-title">Next</div>
            <span className="toggle-btn">
              <FontAwesomeIcon icon={isActive21 ? faAngleUp : faAngleDown} />
            </span>
          </div>
          <div className="filter-group">
            <div className="sort-by-filter">
              <span className="option" value="Next">
                <label htmlFor="next11" className="checkbox">
                  Next
                  <input type="radio" id="next11" name="next" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>

            <div className="sort-by-filter">
              <span className="option" value="Last">
                <label htmlFor="next12" className="checkbox">
                  Last
                  <input type="radio" id="next12" name="next" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>
          </div>
        </div>

        <div className={`toggle-box ${isActive31 ? "active" : ""}`}>
          <div className="title-box" onClick={handleToggle3}>
            <div className="filter-title">Last</div>
            <span className="toggle-btn">
              <FontAwesomeIcon icon={isActive31 ? faAngleUp : faAngleDown} />
            </span>
          </div>
          <div className="filter-group">
            <div className="sort-by-filter">
              <span className="option" value="Last">
                <label htmlFor="last11" className="checkbox">
                  Last
                  <input type="radio" id="last11" name="last" />
                  <span className="checkmark" />
                </label>
              </span>

              <span className="option" value="Last">
                <label htmlFor="last12" className="checkbox">
                  Last
                  <input type="radio" id="last12" name="last" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>
          </div>
        </div>

        <div className={`toggle-box ${isActive41 ? "active" : ""}`}>
          <div className="title-box" onClick={handleToggle4}>
            <div className="filter-title">Custom range</div>
            <span className="toggle-btn">
              <FontAwesomeIcon icon={isActive41 ? faAngleUp : faAngleDown} />
            </span>
          </div>
          <div className="filter-group">
            <div className="sort-by-filter input-date">
              <input
                type="date"
                // value={startingDat.toLocaleDateString("en-CA").split("T")[0]}// value={startingDat.toISOString().split("T")[0]} 
                onChange={handleDateChange(setStartingDat, 1)}
              />{" "}
              <span className="mid-txt">to</span>
              <input
                type="date"
                // value={endingDat.toLocaleDateString("en-CA").split("T")[0]} // value={endingDat.toISOString().split("T")[0]} 
                onChange={handleDateChange(setEndingDat, 2)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="calendar-box">
        {selectFilte === "Quater" && (
          <>
           <RangeCompairSelect childToParent={childToParent} dateRanged={dateRang}/>
          </>
        )}
        {selectFilte === "Month This" && isDateRangeInitialize && (
          <>
            <RangeInputCompair
              childToParent={childToParent}
              dateRange={dateRang}
            />
          </>
        )}
        {selectFilte === "Year" && (
          <>
            <Calendar
              onChange={setDateRang}
              value={dateRang}
              className="custom-calendar"
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            />
          </>
        )}
        {selectFilte === "MonthtoDate" && (
          <>
            <Calendar
              onChange={setDateRang}
              value={dateRang}
              className="custom-calendar"
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            />
          </>
        )}
        {selectFilte === "YeartoDate" && (
          <>
            <Calendar
              onChange={setDateRang}
              value={dateRang}
              className="custom-calendar"
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            />
          </>
        )}
        {selectFilte === "customers" && (
          <>
            <Calendar
            selectRange={true}
              onChange={setDateRang}
              value={dateRang}
              className="custom-calendar"
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            />
          </>
        )}

        <div className="calendar-footer">
          <button className="cancel-button" onClick={closePopup2}>
            Cancel
          </button>
          <button className="apply-button" onClick={submitFunctionCompair}>Apply</button>
        </div>
      </div>
    </div>
  );
};

export default CustomCalendarCompair;
