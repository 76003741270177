import React, { useState } from "react";
import {
  LOGOSMALLBL,
  LOGOWHITE,
  USERROUND,
} from "../../utlis/assests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hotelImage, setHotelImage] = useState(null);
  const [selectCurr, setSelectCurr] = useState([]);
  const [room, setRoom] = useState(0);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };
  const currency = ["Euro", "EUR", "$", "₹", "€", "₽"];
  const subtractValue = () => {
    setRoom((prevRoom) => (prevRoom > 0 ? prevRoom - 1 : 0));
  };
  const addValue = () => {
    setRoom((prevRoom) => prevRoom + 1);
  };
  const handleHotelImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setHotelImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleRoomChange = (e) => {
  console.log("room changed value are called ",e.target.value)
    const value = Number(e.target.value);
      setRoom(Number(value));

  };
  const onSubmit = (data) => {
    const formData = {
      ...data,
      currency: selectCurr,
      hotelImage: hotelImage,
      room: room,
    };
    localStorage.setItem("hotel-information", JSON.stringify(formData));
    reset();
    setHotelImage(null);
    setRoom(0);
    setSelectCurr([]);
  };
  const handleCurrSelect = (cur) => {
    setSelectCurr((prevSelectedCurr) =>
      prevSelectedCurr.includes(cur)
        ? prevSelectedCurr.filter((c) => c !== cur)
        : [...prevSelectedCurr, cur]
    );
    setIsOpen(false);
  };

  return (
    <>
      <div className="sign-in-page default-sin-in">
        <div className="sign-container">
          <div className="mid-align">
            <div className="left-content">
              <div className="logo-icon">
                <img src={LOGOWHITE} alt="Logo" />
              </div>
              <h1>
                <span className="white">About your</span> <br />
                <span className="grad-text">hotel</span>
              </h1>
              <div className="content-box">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Eu ut orci posuere id
                  egestas. Habitasse ut id lacus viverra vitae sagittis sit.
                </p>
              </div>
            </div>
            <div className="right-content">
              <div className="form-box">
                <form className="form-start" onSubmit={handleSubmit(onSubmit)}>
                  <div className="two-col">
                    <div className="left">
                      <h2>The Hotel</h2>
                      <div className="icon-text">
                        <div className="gold">
                          <input
                            type="file"
                            accept="image/*"
                            id="toggle-input"
                            onChange={handleHotelImage}
                          />
                          <label htmlFor="toggle-input">
                            <div className="icon-box">
                              <FontAwesomeIcon icon={faArrowUpFromBracket} />
                            </div>
                            Upload profile picture
                          </label>
                        </div>
                      </div>
                      {/* <span class="error-message">Profile picture is required</span> */}
                    </div>
                    <div className="right">
                      <div className="form-user">
                        <img src={hotelImage || USERROUND} alt="Profile" />
                      </div>
                    </div>
                  </div>

                  <div className="input-box">
                    <label>Name of the hotel</label>
                    <input
                      type="text"
                      placeholder="Enter hotel name"
                      {...register("hotelName", {
                        required: "Hotel name is required",
                      })}
                    />
                    {errors.hotelName && (
                      <span className="error-message">
                        {errors.hotelName.message}
                      </span>
                    )}
                  </div>
                  <div className="input-box">
                    <label>Address</label>
                    <textarea
                      placeholder="Address"
                      {...register("address", {
                        required: "Hotel address is required",
                      })}
                    />
                    {errors.address && (
                      <span className="error-message">
                        {errors.address.message}
                      </span>
                    )}
                  </div>
                  <div className="form-select-box-outer">
                    <div className="form-select-box">
                      <div className="title-box" onClick={toggleOptions}>
                        <span className="selected-label">
                          {selectCurr.length > 0
                            ? selectCurr.join(", ")
                            : "Select currency"}
                        </span>
                        <div className="open-btn">
                          <FontAwesomeIcon
                            icon={isOpen ? faAngleUp : faAngleDown}
                          />
                        </div>
                      </div>
                      {isOpen && (
                        <div className="select-box-option open">
                          <ul>
                            {currency.map((cur, index) => (
                              <li
                                key={index}
                                onClick={() => handleCurrSelect(cur)}
                                className={
                                  selectCurr.includes(cur) ? "selected" : ""
                                }
                              >
                                <label htmlFor={`cur-${index}`}>{cur}</label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="input-box add-remove">
                    <label>Rooms</label>
                    <div className="number-box">
                      <span className="decrease" onClick={subtractValue}>
                        -
                      </span>
                      <input
                        type="number"
                        value={room}

                        onChange={handleRoomChange}
                        {...register("room", {
                          required: "Room count is required",
                          min: 0,
                        })}
                      />
                      <span className="increase" onClick={addValue}>
                        +
                      </span>
                    </div>
                    {errors.room && (
                      <span className="error-message">
                        {errors.room.message}
                      </span>
                    )}
                  </div>

                  <div className="submit-box">
                    <input type="submit" value="Valider" />
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="form-footer-box">
            <div className="form-footer-box-inner">
              <div className="form-button-bottom">
                <FontAwesomeIcon icon={faArrowLeft} />
                Back
              </div>

              <div className="steps-counts">
                <span className="gold">step 01 on 13</span>
                <span className="hexa-icon s-active"></span>
                <span className="white">Setup your access</span>
                <div className="steps-list">
                  <span className="hexa-icon s-active"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                </div>
              </div>

              <div className="form-button-bottom b-active">
                Next
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
