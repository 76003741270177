import axios from "axios";
// const apiUrl = process.env.BACKEND_API;
import {Users} from "../utlis/helper";
const apiUrl = "https://dummyjson.com/products";
const forgotURl = "https://dummyjson.com/products/forgot";
const getAllCountry = async () => {
  try {
    const getAll = await axios.get(`${apiUrl}`);
    return getAll;
  } catch (error) {
    console.error("Error while fetching data:", error);
    throw error;
  }
};
const forgot = async (data )=>{
  try {
    const res = await axios.post(`${forgotURl}`);
    if(!res){
      return {msg :"response are send ",status:400};
    }else{
      return {msg :"forgot password code is  send to this url  ",status:200};
    }
  } catch (error) {
    console.log("Error are in forgot Api call : ",error);
    return {msg:"error are in forgot api call ",status:401,error};
  }
}
const loginApi = async (data) => {
    try {
      const user = Users.find((user) => user.email === data.email);
      if (!user) {
        return { msg: "User Not Found" ,status:404 };
      } else if (user.password !== data.password) {
        return { msg: "Password doesn't match",status:401 };
      } else {
        return {
          token: "xyz",
          refreshToken: "xyz",
          status: 200,
          msg: "User Found",
          user: user,
        };
      }
    } catch (error) {
      console.log("Error in login API call: ", error);
      return { msg: "Error in login API call", error };
    }
  };
  

export { getAllCountry, loginApi,forgot };
