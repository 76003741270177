// Import the necessary dependencies
import React, { useState,useEffect } from "react";
import {
  FILTER_BG,
  SIDE1,
  OVERVIEWBARCHART,
  OVERVIEWHOTEL,
  OVERVIEWCALENDER,
  OVERVIEWCALENDERRANGE,
} from "../../utlis/assests";
import CustomSelect from "../customselect/CustomSelect";
import { days, hotels } from "../../utlis/helper";
import "./Header.css";
import Calendar from "../calendar/CustomCalendar";
import CalenderCustom from "../calendar/CustomCalendarCompair"
import 'react-calendar/dist/Calendar.css';
const FilterBar = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedHotel, setSelectedHotel] = useState("Hôtel California");
  const [selectedDay, setSelectedDay] = useState("This Month");
  const [selectedPeriod, setSelectedPeriod] = useState("Last Month");
  const [selectedComparison, setSelectedComparison] = useState("7 days");
  const [value, onChange] = useState(new Date());
  const [isActive, setIsActive] = useState(true);
  const [isActive2, setIsActive2] = useState(true);
  const [val, setVal] = useState(null);
  const [valu, setValu] = useState(null);
// value  -->>  value will hold the current date and time when the component is rendered.
// onChange  -->>  onChange is a function that will be called whenever the user selects a new
  const [customRange, setCustomRange] = useState(null);
  const ChildToParentPeriod = (val) => {
    setVal(val);
  };

  useEffect(() => {
   if(!val){
    setSelectedDay("This Month")
   }else{
    const startDate = new Date(val[0]);
    const endDate = new Date(val[1]);
    const options = { month: 'short', day: '2-digit' };
    const formattedStartDate = startDate.toLocaleDateString('en-US', options);
    const formattedEndDate = endDate.toLocaleDateString('en-US', options);
      setSelectedDay(`${formattedStartDate} - ${formattedEndDate}`);
   }
  }, [val]);
  const ChildToParentComapir = (valu) => {
    // console.log("value are :49 ", valu);
    setValu(valu);
  };

  useEffect(() => {
   if(!valu){
    setSelectedPeriod("Last Month")
   }else{
    const startDate = new Date(valu[0]);
    const endDate = new Date(valu[1]);
    const options = { month: 'short', day: '2-digit' };
    const formattedStartDate = startDate.toLocaleDateString('en-US', options);
    const formattedEndDate = endDate.toLocaleDateString('en-US', options);
      setSelectedPeriod(`${formattedStartDate} - ${formattedEndDate}`);
   }
  }, [valu]);

  const openPopup = () => {
    setIsActive(true);
  };
  const openPopup2 = () => {
    console.log("isopen function cllnwe   73")
    setIsActive2(true);
  };

  const closePopup = () => {
    console.log("calendar popup 1 closed ")
    setIsActive(false);
  };
  const closePopup2 = () => {
    setIsActive2(false);
  };

  // Use useEffect to log the state change
  useEffect(() => {
  }, [isActive2]);

  useEffect(() => {setIsActive(true)},[!isActive])
  useEffect(() => {setIsActive2(true)},[!isActive2])
  const handleHotelChange = (event) => {
    setSelectedHotel(event.target.value);
  };
  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };
  const handleComparisonChange = (event) => {
    setSelectedComparison(event.target.value);
  };

  return (
    <div className="dis-flex content-between dashboard-top-head">
      <div className="sort-by-filter">
        <span className="gold">
          <img loading="lazy" src={OVERVIEWHOTEL} alt="icon" /> Hôtel
        </span>
        <div className="select-box">
          <span>{selectedHotel}</span>
        </div>
        <div className="option-box"> 
          <span className="option" value="Hôtel California">
            <label htmlFor="hotel1" className="checkbox" onChange={() => {
                  setSelectedHotel("Hôtel California");
                }}>
              Hôtel California
              <input
                type="radio"
                id="hotel1"
                name="hotel"
                checked={selectedHotel == "Hôtel California"}
              
              />
              <span className="checkmark" />
            </label>
          </span>
          <span className="option" value="Hôtel California2 ">
            <label htmlFor="hotel2" className="checkbox"  onChange={() => {
                  setSelectedHotel("Hôtel California2");
                }}>
              Hôtel California2
              <input
                type="radio"
                id="hotel2"
                name="hotel"
                checked={selectedHotel == "Hôtel California2"}
               
              />
              <span className="checkmark" />
            </label>
          </span>
        </div>
      </div>

      {/* Period Filter */}
      <div className="pick-call dis-flex">
        <div className="sort-by-filter">
          <span className="gold">
            <img src={OVERVIEWCALENDER} alt="icon" /> Period
          </span>
          <div className="select-box">
            <span>{selectedDay}</span>
          </div>
          <div className="option-box calendar-v1 " >

              
          {isActive && <Calendar isActive={isActive} closePopup={closePopup} ChildToParentPeriod={ChildToParentPeriod}/>}
          </div>
        </div>

        {/* Compared to Filter */}
        <div className="sort-by-filter">
          <span className="gold">
            <img src={OVERVIEWCALENDERRANGE} alt="icon" /> Compared to{" "}
          </span>
          <div className="select-box">
            <span>{selectedPeriod}</span>
          </div>
          <div className="option-box calendar-v1" 
          // onClick={openPopup2}
          >
            
            {isActive2 && <CalenderCustom isActiv={isActive2} closePopup2={closePopup2} ChildToParentComapir={ChildToParentComapir} />}

          </div>
        </div>

        <div className="sort-by-filter">
          <span className="gold">
            <img src={OVERVIEWBARCHART} alt="icon" /> Pick-up date
          </span>
          <div className="select-box">
            <span>{selectedComparison}</span>
          </div>
          <div className="option-box">
            <span className="option" value="7 days">
              <label htmlFor="pickUp1" className="checkbox" checked={selectedComparison == "7 days"}
                  onChange={() => {
                    setSelectedComparison("7 days");
                  }}>
                7 days
                <input
                  type="radio"
                  id="pickUp1"
                  name="pickUp"
                  checked={selectedComparison=="7 days"}
                />
                <span className="checkmark" />
              </label>
            </span>
            <span className="option" value="15 days">
              <label htmlFor="pickUp2" className="checkbox" checked={selectedComparison == "15 days"}
                  onChange={() => {
                    setSelectedComparison("15 days");
                  }}>
                15 days
                <input
                  type="radio"
                  id="pickUp2"
                  name="pickUp"
                 checked={selectedComparison=="15 days"}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;

