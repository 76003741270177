import React, { useState } from "react";
import { HOTELIMG, LOGOSMALLBL, LOGOWHITE, USERROUND } from '../../utlis/assests';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleUp,
    faAngleDown,
    faArrowLeft,
    faArrowRight,
    faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const AboutAdd  =()=>{

    const [isOpen, setIsOpen] = useState(false);

    const toggleOptions = () => {
      setIsOpen(!isOpen);
    };
  

    return(
    <>
    <div className="about-page default-sin-in">
        <div className="sign-container">
            <div className="top-header">Hotel California</div>

            <div className="header-box">
                <h1><img src={LOGOWHITE} alt="Logo" /> <span className="white">About your</span> <span className="grad-text">hotel</span></h1>
            </div>

            <div className="top-links">
                <Link to="#"><FontAwesomeIcon icon={faArrowLeft} /> Retour</Link>
                <Link to="#">Skip this step <FontAwesomeIcon icon={faArrowRight} /></Link>
            </div>


            <div className="form-footer-box">

                <div className="form-footer-box-inner">
                    <div className="form-button-bottom">
                        <FontAwesomeIcon icon={faArrowLeft} />
                        Back
                    </div>

                    <div className="steps-counts">
                        <span className="gold">step 01 on 13</span>
                        <span class="hexa-icon s-active"></span>
                        <span className="white">
                            Setup your access
                        </span>
                        <div className="steps-list">
                            <span class="hexa-icon s-active"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                        </div>
                    </div>

                    <div className="form-button-bottom b-active">
                        Next
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                </div>
            </div>


        </div>
    </div>
    </>
    )
}
export default AboutAdd;