 import classes from "./card.module.css";
import "./ProductCard.css"
const CountryCard = ({ name, thisyear, lastyear }) => {
  
  return (
    // <div className="flex flex-col gap-2.5 mt-3">
        
    //  <div className="mb-2">
    //  <div className={classes.cname}>{name}</div>  
    //   <div className={`flex gap-3.5 justify-between text-base rounded ${classes.seprateline}`}>
    //     <div className={classes.year}>This year</div>
    //     <div className={classes.rev}>{thisyear}M</div>
    //   </div>
    //   <div className={`flex gap-3.5 justify-between text-base rounded ${classes.seprateline}`}>
    //   <div className={classes.year}>Last year</div>
    //     <div className={classes.rev}>{`${lastyear}  M`}</div>
    //   </div>
    //  </div>
    // </div>
    <>
 
  <div className="chart-column">
  
    <div className="country c-list">
      <h5 className="safiro">{name}</h5>
      <div className="table">
        <div className="table-list dis-flex">
          <span>This year</span>
          <span>{thisyear} M</span>
        </div>
        <div className="table-list dis-flex">
          <span>Last year</span>
          <span>{lastyear} M</span>
        </div>
      </div>
    </div>
   
  </div>
 
</>

  );
};

export default CountryCard;
