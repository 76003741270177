import React, { useState, useEffect } from "react";
import { MetricData } from "../../utlis/metric";
import MetricCard from "./MetricCard";
import ProductCard from "./ProductCard";
import {getAllCountry}from "../../config/DataApi";
import { countryDataChange } from "../../utlis/helper";
const Cards = ({ type, data }) => {
  const [alldata, setAlldata] = useState([]);
 useEffect(() => {setAlldata(data);});

  return (
    <div className="mt-4 2xl:p-5 xl:p-3 max-md:max-w-full">
      <div className="flex gap-4 max-md:flex-col max-md:gap-0">
        {
          type === "overview" ? (
            // MetricData.map((metric) => (
            //   <MetricCard key={metric.id} {...metric} />
            // ))
            <MetricCard  />
          ) : (
            countryDataChange.map((product) => (
              <>
         
              <ProductCard  product={product.id} name={product.name} />
              </>
            ))
          )
        }
      </div>
    </div>
  );
};

export default Cards;