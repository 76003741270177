import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";

const RangeInputCalendar = ({ childToParent, dateRanged }) => {
  const firstDayOfMonth = new Date(dateRanged[0]);
  const lastDayOfMonth = new Date(dateRanged[1]);
  const [dateRange, setDateRange] = useState(dateRanged);

  useEffect(() => {
    setDateRange(dateRanged);
  }, [dateRanged]);

  const handleDateChange = (dates) => {
   
    
    childToParent(dates);

  };

  // const tileDisabled = ({ date }) => {
    // return date < firstDayOfMonth || date > lastDayOfMonth;
  // };

  return (
    <div className="range-input-calendar">
      <Calendar
        // selectRange={true}
        onChange={handleDateChange}
        value={dateRange}
        // minDate={firstDayOfMonth}
        // maxDate={lastDayOfMonth}
        // tileDisabled={tileDisabled}
        className="custom-calendar"
        nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
        next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
        prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
      />
    </div>
  );
};

export default RangeInputCalendar;
