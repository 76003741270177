 
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import classes from './sidebar.module.css';
 
import NavItem from './NavItem';
import { ANALYTICS_ICON, FORECAST_ICON,PROFILE_IMAGE,SLIDELEFTBTN, CLOUD,LOGO, MARKET_ICON, OVERVIEW_ICON, PICKUP_ICON, PORTFOLIO_ICON, UP_ARROW } from '../../utlis/assests';
import "./SideBar.css"
const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
 
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSubmenuVisible1, setIsSubmenuVisible1] = useState(false);
  const [isSubmenuVisible2, setIsSubmenuVisible2] = useState(false);
  const [isSubmenuVisible3, setIsSubmenuVisible3] = useState(false);
  const[slideSubmenu,setSlideSubmenu]=useState(false);
 const [hoverColor,setHoverColor]=useState();
  useEffect(() => {
    if (location.pathname === '/analytic') {
      setIsSubmenuVisible2(true);
      setIsSubmenuVisible1(false);
      setIsSubmenuVisible3(false);
      setHoverColor(2);
    }
    else if(location.pathname==='/market'||location.pathname==='/forecast'||location.pathname==="/pickup"||location.pathname==="/") {
      setIsSubmenuVisible2(false);
      setIsSubmenuVisible1(true);
      setIsSubmenuVisible3(false);
      setHoverColor(1);
    }
    else if(location.pathname==='/info') {
      setIsSubmenuVisible2(false);
      setIsSubmenuVisible1(false);
      setIsSubmenuVisible3(true);
      setHoverColor(3);
    }
  }, [location.pathname]);
 
 const managedsubmanu =(e)=>{
  if(slideSubmenu){
    setSlideSubmenu(!slideSubmenu);
  }else{
    setSlideSubmenu(true);
  }
 }
 
  return (
<div className={"sidebar" + (slideSubmenu ? " active" : "")}>
{/* <div className="sidebar "> */}
  <div>
    <div className="logo">
      <img src={LOGO} alt="logo" />
    </div>
    <div className="menu">
      <div className="menu-title active"
           onClick={() => {
          setIsSubmenuVisible1(!isSubmenuVisible1);
          setActiveIndex(0);
        }}
      >
        <div>
          <a href="#" className="">
            <img src={PORTFOLIO_ICON} alt="Icon" />{" "}
            <span className="hide-text-m">Porfolio</span>
          </a>
        </div>
        <div className="menu-btn">
          <img src={UP_ARROW} alt="button" />
        </div>
      </div>
      {isSubmenuVisible1 && (<div className="submenu">
        <Link to="/" className={` ${location.pathname === '/'?'side-active':" "}`}>
          <div className="sub-head">
            <span>
              <img src={OVERVIEW_ICON} alt="Icon" />{" "}
              <span className="hide-text-m">Overview</span>
            </span>
            <span>
              <span className="num">12</span>
            </span>
          </div>
        </Link>
        <Link to="/market"  className={` ${location.pathname === '/market'?'side-active':" "}`}>
          <div className="sub-head">
            <span>
              <img src={MARKET_ICON} alt="Icon" />{" "}
              <span className="hide-text-m">Market</span>
            </span>
            <span className="num">2</span>
          </div>
        </Link>
        <Link to="/forecast" className={` ${location.pathname === '/forecast'?'side-active':" "}`}>
          <div className="sub-head">
            <span>
              <img src={CLOUD} alt="Icon" />{" "}
              <span className="hide-text-m">Forecast</span>
            </span>
            <span className="num">4</span>
          </div>
        </Link>
        <Link to="/pickup"  className={` ${location.pathname === '/pickup'?'side-active':" "}`}>
          <div className="sub-head">
            <span>
              <img src={PICKUP_ICON} alt="Icon" />{" "}
              <span className="hide-text-m">Pick-up</span>
            </span>
            <span className="num">9</span>
          </div>
        </Link>
      </div>)}
     
      <div className={`menu-title ${isSubmenuVisible2?'active':''} `} onClick={() => {
          setIsSubmenuVisible2(!isSubmenuVisible2);
          setActiveIndex(0);
        }}>
        <div>
          <a href="#" className="">
            <img src={PORTFOLIO_ICON} alt="Icon" />{" "}
            <span className="hide-text-m">Analytics</span>
          </a>
        </div>
        <div className="menu-btn">
          <img src={UP_ARROW} alt="button" />
        </div>
      </div>
      {isSubmenuVisible2 && (<div className="submenu">
        <Link to="/analytic"  className={` ${hoverColor==2?'side-active':" "}`}>
          <div className="sub-head">
            <span>
              <img src={OVERVIEW_ICON} alt="Icon" />{" "}
              <span className="hide-text-m">Analytics</span>
            </span>
            <span className="num">12</span>
          </div>
        </Link>
      </div>)}
     
      <div className={`menu-title ${isSubmenuVisible3?'active':''} `}
       onClick={() =>{
          setIsSubmenuVisible3(!isSubmenuVisible3);
          setActiveIndex(0);
        }}>
        <div>
          <a href="#" className="">
            <img src={PORTFOLIO_ICON} alt="Icon" />{" "}
            <span className="hide-text-m">Info autres</span>
          </a>
        </div>
        <div className="menu-btn">
          <img src={UP_ARROW} alt="button" />
        </div>
      </div>
      {isSubmenuVisible3 && (<div className="submenu">
        <Link to="/info"  className={` ${hoverColor==3?'side-active':" "}`}>
          <div className="sub-head">
            <span>
              <img src={PORTFOLIO_ICON} alt="Icon" />{" "}
              <span className="hide-text-m">Info</span>
            </span>
            <span className="num">12</span>
          </div>
        </Link>
      </div>)}
     
    </div>
    <div className="close-btn">
      <img src={SLIDELEFTBTN} alt="icon" onClick={(e)=>managedsubmanu()}/>
    </div>
  </div>
  <div className="profile-outer">
    <div className="profile-img">
      <div className="left-block">
        <img src={PROFILE_IMAGE} alt="profile" />
        <span className="profile-name">Profil</span>
      </div>
      <div className="profile-btn">
        <span />
        <span />
        <span />
      </div>
    </div>
    <div className="profile-bottom-txt">The AI Innovating hive</div>
  </div>
</div>
 
 
   
  );
}
 
export default SideBar;
 