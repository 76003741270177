import * as React from 'react';
 import "./Notification.css"
 import { BELL2 ,INFO2,SETTING2} from "./../../utlis/assests";
 const Notification=()=>{
    return (<>
      
        <div className="notification-setting">
          <div className="icon">
            <img src={BELL2} alt="icon" />
            <span className="notification">24</span>
          </div>
          <div className="icon">
            <img src={INFO2} alt="icon" />
            <div className="info-show">
              Shot Details
              <span className="arrow" />
            </div>
          </div>
          <div className="icon">
            <img src={SETTING2} alt="icon" />
          </div>
        </div>
      </>
      )
 }
 export default Notification