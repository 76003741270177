import { Route, Routes, BrowserRouter } from "react-router-dom";
import Layout from "./layout/Layout";
import Analytic from "./pages/Analytic";
import ProtectedRoute from "./protectedRoute/ProtectedRoute";
import Info from "./pages/Info";
// import Overview from "./pages/Overview";
import Market from "./pages/Market";
import Forecast from "./pages/Forecast";
import Pickup from "./pages/Pickup";
import SignIn from "./components/sign-in/SignIn";
import CreateAcc from "./components/create-password/CreateAcc";
import Forgot from "./components/forgot-password/Forgot";
import ProfileSetup from "./components/profile-setup/ProfileSetup";
import YourTeam from "./components/your-team/YourTeam";
import About from "./components/about-your-hotel/About";
import AboutAdd from "./components/about-add/AboutAdd";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute element={Layout} />} />
        <Route
          path="/analytic"
          element={<ProtectedRoute element={Analytic} />}
        />
        <Route path="/info" element={<ProtectedRoute element={Info} />} />
        <Route path="/market" element={<ProtectedRoute element={Market} />} />
        <Route
          path="/forecast"
          element={<ProtectedRoute element={Forecast} />}
        />
        <Route path="/pickup" element={<ProtectedRoute element={Pickup} />} />
        <Route
          path="/create-account"
          element={<ProtectedRoute element={CreateAcc} />}
        />
        <Route 
        path="/profile-setup"
        element={<ProtectedRoute element={ProfileSetup}/>}/>
        <Route
        path="/your-team" element={<ProtectedRoute element={YourTeam}/>}/>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/create-account" element={<CreateAcc />} />
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/about" element={<About />} />
        <Route path="/about-add" element={<AboutAdd />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
