import { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const [data, setData] = useState({
    user: null,
    token: "",
    helperData:""
  });

//   axios.defaults.headers.common["Authorization"] = auth?.token;

  useEffect(() => {
    const email = localStorage.getItem("user");
    const type = localStorage.getItem("userType");
    const token = localStorage.getItem("token");
    if (!token) {
        // console.log("there is no data null used context hook ");
      const parseData = JSON.parse(token);
      // const parseData =data;
    //   const parseToken =JSON.parse(token);
    setData({
        ...token,
        // user: parseData,
        // token: parseToken,
        helperData :parseData
      });
    }else if(token){
      console.log("function called ")
      let parseData = {};
      parseData = { email, type };
      // const parseData =data;
      // const parseToken =token;
    setData({
        ...token,
        user: parseData,
        // token: parseToken,
        // helperData :parseData
      });
    }
  }
 , []);
  return (
    <AuthContext.Provider value={[data,setData]}>
      {children}
    </AuthContext.Provider>
  );
};

// custom hook
const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };


/**import {createcon} from 'react
 * export const UserContext=createContext(null)
 */