// import * as React from 'react';
import {react ,useState} from 'react'
 import "./BreadCrumb.css"
 

const  Breadcrumbs = () => {
  const [selecet,setSelecet]=useState("Euro")
  return (
  //   <div className="flex gap-5 justify-between mt-10 ml-6 font-medium max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
  //   <div className="flex gap-2.5 text-base font-medium whitespace-nowrap">
  //     <div className="text-orange-300">Dashboard</div>
  //     <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b97b1a1649e44d7daf57a361f08d90903aa4dd22eaf6eca155c25cdf2e67ae42?apiKey=6e2bbf49019440de822d58369c2af183&" alt="" className="shrink-0 aspect-square w-[18px]" />
  //     <div className="text-stone-950">Overview</div>
  //   </div>
    
  // </div>
  <div className="dis-flex breadcrum-outer ml-6">
  <div className="breadcrum">
    <span className="font-16">
      <a href="#" className="font-16 gold b-link">
        Dashboard
      </a>{" "}
      Overview
    </span>{" "}
    <span className="last-updated">
      Last updated : <span>5 minutes</span> ago
    </span>
  </div>
  <div className="font-16 head-right">
    <div className="sort-by-filter">
      <span className="gold">Devise</span>
      <div className="select-box">
        <span>{selecet}</span>
      </div>
      <div className="option-box">
        <span className="option">
          <label className="checkbox" value={"Euro"}>
            Euro
            <input type="checkbox" checked={selecet=="Euro"}
            onChange={()=>{
              setSelecet("Euro");
            }} defaultChecked="checked" />
            <span className="checkmark" />
          </label>
        </span>
        <span className="option">
          <label className="checkbox" value="Dollar">
            Dollar
            <input type="checkbox"  checked={selecet=="Dollar"} onChange={()=>{setSelecet("Dollar")}}/>
            <span className="checkmark" />
          </label>
        </span>
      </div>
    </div>
  </div>
</div>

  );
};

export default Breadcrumbs;