import React from 'react'
import FilterBar from "../components/FilterBar/FilterBar";
import Breadcrumbs from "../components/breadcrumb/BreadCrumb";
import Cards from "../components/cards/Cards";
import SideBar from "../components/sidebar/SideBar";
import Content from "../content/content";
import ChartApplied from "../components/chart/ChartApplied"
import { CHART_IMG, FILTER, SELECTED_DOT, STLY_DOT } from "../utlis/assests";

const Forecast = () => {
  return (
    <>
      <div className="flex flex-col justify-center bg-white">
        <div className="flex gap-5 items-start px-4 pt-6 pb-20 bg-stone-950 max-md:flex-wrap max-md:px-5  ">
          <SideBar />
          <div className=" main-dashboard  flex-wrap grow shrink-0 justify-center pr-7 pb-20 rounded-3xl basis-0 bg-stone-50 w-fit max-md:px-5 max-md:max-w-full">
            <div className="flex gap-5 justify-between items-start ml-6 max-md:flex-wrap">
              {/* <FilterBar /> */}
            </div>
            {/* <Breadcrumbs /> */}
            <h1
              className="mt-8 ml-6 text-8xl italic gradient-text leading-[95px] max-md:max-w-full max-md:text-4xl"
              style={{ fontFamily: "Sfizia Regular" }}
            >
              Forecast
            </h1>
          
            
            
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Forecast;
