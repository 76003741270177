import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";

const RangeCustomSelect = ({ childToParent, dateRanged }) => {
  const today = new Date();
 
  const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());


  const [dateRange, setDateRange] = useState(dateRanged);

  useEffect(() => {
    setDateRange(dateRanged);
  }, [dateRanged]);

  const handleDateChange = (dates) => {
    // setDateRange(dates);
    // if (dates.length === 2) {
    //   const [start, end] = dates;
    //   const startVal = start.getTime();
    //   const endVal = end.getTime();
      childToParent(dates);
    // }else if(dates.length===1){
    //   console.log("one click function : ",)
    // }else{
    //   console.log("no click")
    // }
  };


  const tileDisabled = ({ date }) => {
    return date < threeMonthsAgo || date > dateRanged[1];
  };

  return (
    <div className="range-input-calendar">
      <Calendar
        selectRange={true}
        onChange={handleDateChange}
        value={dateRange}
        // minDate={threeMonthsAgo}
        // maxDate={today}
        // tileDisabled={tileDisabled}
        className="custom-calendar"
        nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
        next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
        prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
      />
    </div>
  );
};

export default RangeCustomSelect;
