import{ React,useState} from 'react';
 import "./dwnnevbar.css"
//  import { BELL2 ,INFO2,SETTING2} from "./../../utlis/assests";
 const DownNavBar=()=>{
  const [active,setActive]=useState("")

  const handleSetActive = (value) => {
    setActive(value);
  };
    return (
<>
 
  <div className="bottom-over-short">
    <div className="bottom-over-short-inner">
      <ul className="left-block">
        <li className="active">
          <span className="hexa-icon" /> Room Revenue{" "}
          <span className="dollar">$4.0M</span>{" "}
          <span className="green">+ 100</span>
        </li>
      </ul>
      <ul className="right-block">
        <li className={` ${active=="1"?"active":""}`}>
          <div  onClick={() => handleSetActive("1")} >
          <span className="hexa-icon" /> ADR{" "}
          <span className="dollar">$4.0M</span>{" "}
          <span className="green">+ 100</span>
          </div>
         
        </li>
        <li className={` ${active=="2"?"active":""}`}>
          <div  onClick={() => handleSetActive("2")} >
          <span className="hexa-icon" /> Occupancy Rates{" "}
          <span className="dollar">$4.0M</span>{" "}
          <span className="green">+ 100</span>
          </div>
        </li>
        <li className={` ${active=="3"?"active":""}`}>
          <div  onClick={() => handleSetActive("3")} >
          <span className="hexa-icon" /> RevPAR{" "}
          <span className="dollar">$4.0M</span>{" "}
          <span className="green">+ 100</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</>

      )
 }
 export default DownNavBar