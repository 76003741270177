import React, { useState } from "react";
import { LOGOSMALLBL, LOGOWHITE, USERROUND } from "../../utlis/assests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const ProfileSetup = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [profilePicture, setProfilePicture] = useState(null);
  const options = [
    "Français",
    "Hindi",
    "Spanish",
    "Arabic",
    "English",
    "Urdu",
    "French",
    "German",
  ];

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option));
    }
    setIsOpen(false);
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfilePicture(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmit = (data) => {
    const formData = {
      ...data,
      languages: selectedOptions,
      profilePicture: profilePicture ? profilePicture : null,
    };
    console.log(formData);
    localStorage.setItem("onboarding-profile", JSON.stringify(formData));
    navigate("/your-team");
  };

  return (
    <>
      <div className="sign-in-page default-sin-in">
        <div className="sign-container">
          <div className="mid-align">
            <div className="left-content">
              <div className="logo-icon">
                <img src={LOGOWHITE} alt="Logo" />
              </div>
              <h1>
                <span className="white">Setup your</span> <br />
                <span className="grad-text">profile</span>
              </h1>
              <div className="content-box">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Eu ut orci posuere id
                  egestas. Habitasse ut id lacus viverra vitae sagittis sit.
                </p>
              </div>
            </div>
            <div className="right-content">
              <div className="form-box">
                <form className="form-start" onSubmit={handleSubmit(onSubmit)}>
                  <div className="two-col">
                    <div className="left">
                      <h2>Create my profile</h2>
                      <div className="icon-text">
                        <div className="gold">
                          <input
                            type="file"
                            accept="image/*"
                            id="toggle-input"
                            onChange={handleProfilePictureChange}
                          />
                          <label htmlFor="toggle-input">
                            <div className="icon-box">
                              <FontAwesomeIcon icon={faArrowUpFromBracket} />
                            </div>
                            Upload profile picture
                          </label>
                        </div>
                      </div>
                      {/* <span class="error-message">Profile picture is required</span> */}
                    </div>
                    <div className="right">
                      <div className="form-user">
                        <img src={profilePicture || USERROUND} alt="Profile" />
                      </div>
                    </div>
                  </div>
                  <div className="input-box">
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="Enter your First Name"
                      {...register("firstName", {
                        required: "First name is required",
                      })}
                    />
                    {/* <span class="error-message">Profile picture is required</span> */}
                  </div>
                  <div className="input-box">
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Enter your Surname"
                      {...register("LastName", { required: "Last Name is required" })}
                    />
                    {/* <span class="error-message">Last name is required</span> */}
                  </div>
                  <div className="form-select-box-outer">
                    <div className="form-select-box">
                      <div className="title-box" onClick={toggleOptions}>
                        <span className="selected-label">
                          {selectedOptions.length > 0
                            ? selectedOptions.join(", ")
                            : "Select language"}
                        </span>
                        <div className="open-btn">
                          <FontAwesomeIcon
                            icon={isOpen ? faAngleUp : faAngleDown}
                          />
                        </div>
                      </div>
                      {isOpen && (
                        <div className="select-box-option open">
                          <ul>
                            {options.map((option, index) => (
                              <li
                                key={index}
                                onClick={() => handleOptionSelect(option)}
                                className={selectedOptions.includes(option) ? "selected" : ""}
                              >
                                {option}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    {/* <span class="error-message">Language is required</span> */}
                  </div>
                  <div className="submit-box">
                    <input type="submit" value="Valider" />
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="form-footer-box">
            <div className="form-footer-box-inner">
              <div className="form-button-bottom">
                <FontAwesomeIcon icon={faArrowLeft} />
                Back
              </div>

              <div className="steps-counts">
                <span className="gold">step 01 on 13</span>
                <span className="hexa-icon s-active"></span>
                <span className="white">Setup your access</span>
                <div className="steps-list">
                  <span className="hexa-icon s-active"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                </div>
              </div>

              <div className="form-button-bottom b-active">
                Next
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSetup;

//one language select code here ***************************************
//  *****************************i applied multile leanguage select code 
{/* <div className="form-select-box-outer">
<div className="form-select-box">
  <div className="title-box" onClick={toggleOptions}>
    <span className="selected-label">{selectedOption}</span>
    <div className="open-btn">
      <FontAwesomeIcon
        icon={isOpen ? faAngleUp : faAngleDown}
      />
    </div>
  </div>
  {isOpen && (
    <div className="select-box-option open">
      <ul>
        {options.map((option, index) => (
          <li
            key={index}
            onClick={() => handleOptionSelect(option)}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  )}
</div> */}