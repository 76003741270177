import React, { useState, useEffect } from "react";
import FilterBar from "../components/FilterBar/FilterBar";
import Breadcrumbs from "../components/breadcrumb/BreadCrumb";
import Cards from "../components/cards/Cards";
import SideBar from "../components/sidebar/SideBar";
import Content from "../content/content";
import ChartApplied from "../components/chart/ChartApplied";
import Notification from "../components/notification/Notification";
import MyChart from "../components/chart/MyChart";
import ChartComponent from "../components/chart/ChartComponent";
import { CHART_IMG, FILTER, SELECTED_DOT, STLY_DOT } from "../utlis/assests";
import { getAllCountry } from "../config/DataApi";
import DownNavBar from "../components/downNavBAR/DownNavBar";
import StickeyNevbartop from "../components/stickeyNevbartop/StickeyNevbartop";
import CustomCalendar from "../components/calendar/CustomCalendar";
// import { FILTER } from "../utlis/assests";
import "./Production.css";
const Layout = () => {
  const [selectedPeriod, setSelectedPeriod] = useState("Performance");
  const [isActive, setIsActive] = useState(false);
  const dataCountry = async () => {
    try {
      const { data } = await getAllCountry();
      console.log("data are fetch : ", data);
    } catch (error) {
      console.log("error: 13 ", error);
    }
  };
  const handleToggle = () => {
console.log("fucntion r")
    setIsActive(!isActive);
  };
  useEffect(() => {
    dataCountry();
  }, []);
  const ProductData = [
    {
      id: 1,
      country: {
        France: {
          thisYear: "2.4",
          lastYear: "2.8",
        },
        Germany: {
          thisYear: "2.8",
          lastYear: "2.8",
        },
        USA: {
          thisYear: "2.3",
          lastYear: "2.3",
        },
        China: {
          thisYear: "2.6",
          lastYear: "2.6",
        },
        Italy: {
          thisYear: "2.2",
          lastYear: "2.2",
        },
      },
      overallProfit: "9.3",
      addProduct: "116",
      lastYearSale: "2.9M",
      lastYearProfit: "30",
      forecast: "20",
      forecastProduct: "233",
      profitable: "100",
      thinkProfit: "40",
      budget: "98",
      predictionProfit: "90",
      profitable: "40",
      increaseProfit: "30",
    },
    {
      id: 2,
      country: {
        Japan: {
          thisYear: "2.1",
          lastYear: "1.9",
        },
        India: {
          thisYear: "2.5",
          lastYear: "2.4",
        },
      },
      overallProfit: "10.5",
      addProduct: "120",
      lastYearSale: "3.2M",
      lastYearProfit: "35",
      forecast: "25",
      forecastProduct: "250",
      profitable: "95",
      thinkProfit: "45",
      budget: "105",
      predictionProfit: "92",
      profitable: "45",
      increaseProfit: "33",
    },
    {
      id: 3,
      country: {
        Canada: {
          thisYear: "2.3",
          lastYear: "2.2",
        },
        Australia: {
          thisYear: "2.7",
          lastYear: "2.5",
        },
      },
      overallProfit: "8.9",
      addProduct: "110",
      lastYearSale: "2.7M",
      lastYearProfit: "28",
      forecast: "18",
      forecastProduct: "200",
      profitable: "85",
      thinkProfit: "38",
      budget: "95",
      predictionProfit: "88",
      profitable: "38",
      increaseProfit: "27",
    },
    {
      id: 4,
      country: {
        Brazil: {
          thisYear: "2.0",
          lastYear: "1.8",
        },
        Russia: {
          thisYear: "2.3",
          lastYear: "2.2",
        },
      },
      overallProfit: "9.8",
      addProduct: "125",
      lastYearSale: "3.0M",
      lastYearProfit: "32",
      forecast: "22",
      forecastProduct: "240",
      profitable: "90",
      thinkProfit: "42",
      budget: "100",
      predictionProfit: "85",
      profitable: "42",
      increaseProfit: "35",
    },
  ];
  // console.log("product data : ",ProductData)
  return (
    <>
      <div className="flex flex-col justify-center bg-white">
        <div className="flex  items-start px-4 pt-6 pb-20 bg-stone-950 max-md:flex-wrap max-md:px-5 main-dashboard">
          <SideBar />
          <div className=" flex flex-col flex-wrap grow shrink-0 justify-center content-center pr-7 pb-20 rounded-3xl basis-0 bg-stone-50 w-fit max-md:px-5 max-md:max-w-full">
            <div className="d-wrapper topheaderstickey flex gap-5 justify-between items-start max-md:flex-wrap">
              <FilterBar />
            </div>
            <div className="d-wrapper topsecondheaderstickey flex gap-5 justify-between items-start  max-md:flex-wrap">
              <StickeyNevbartop />
            </div>
            <div className="d-wrapper">
              <Breadcrumbs />
            </div>
            <div className="d-wrapper">
              <Notification />
            </div>
            <div className="d-wrapper">
              <h1
                className=" mt-8 text-8xl italic gradient-text leading-[95px] max-md:max-w-full max-md:text-4xl"
                style={{ fontFamily: "Sfizia Regular" }}
              >
                Overview
              </h1>
            </div>
            <div className="d-wrapper">
              <Cards type="overview" data={ProductData} />
              {/* <img src={CHART_IMG} alt="" /> */}
              <ChartApplied />
            </div>
            {/* <MyChart/> */}
            {/* <ChartComponent/> */}
            <div className=" d-wrapper">
              <div className="bg-white mt-8 rounded-xl">
                {/* <div className="flex justify-between items-center px-8 pt-8 fontSSafiro">
                <div>
                  <span className="text-[17px] text-[#0F0F0F]">
                    Production breakdown
                  </span>
                  <span className="ml-10"><img src={SELECTED_DOT} alt="" className="inline mr-2" /> Selected period </span>
                  <span className="ml-10"><img src={STLY_DOT} alt="" className="inline mr-2" /> STLY</span>
                </div>
                <div>
                <span className="text-[16px] text-[#D9A25D] mr-4 Sfizia "><img src={FILTER} alt="" className="inline mr-1" /> Sort by</span>
                <span className="text-[10px] mr-4">|</span>
                 Performance
                </div>
              </div> */}
                <>
                  <div className="column-chart-header">
                    <div className="left-box">
                      <span className="font-17 safiro">
                        Production breakdown
                      </span>
                      <span className="font-13 gold-circ">Selected period</span>
                      <span className="font-13 light-gold-circ">STLY</span>
                    </div>
                    <div className="sort-by-filter">
                      <img src={FILTER} alt="" className="inline mr-1" />{" "}
                      <span className="gold">Sort by</span>
                      <div className={`select-box ${isActive?"true":""}`} onClick={handleToggle}>
                        <span>{selectedPeriod}</span>
                      </div>
                      <div className="option-box">
                        <span className="option">
                          <label
                            className="checkbox"
                            onChange={() => {
                              setSelectedPeriod("Performance");
                            }}
                          >
                            Performance
                            <input
                              type="checkbox"
                              checked={selectedPeriod == "Performance"}
                            />
                            <span className="checkmark" />
                          </label>
                        </span>
                        <span className="option">
                          <label
                            className="checkbox"
                            onChange={() => {
                              setSelectedPeriod("Month");
                            }}
                          >
                            Month
                            <input
                              type="checkbox"
                              checked={selectedPeriod == "Month"}
                            />
                            <span className="checkmark" />
                          </label>
                        </span>

                        <span className="option">
                          <label
                            className="checkbox"
                            onChange={() => {
                              setSelectedPeriod("Week");
                            }}
                          >
                            Week
                            <input
                              type="checkbox"
                              checked={selectedPeriod == "Week"}
                            />
                            <span className="checkmark" />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
{/* <h1>jskjdk</h1>                          <CustomCalendar/> */}
                <Cards data={ProductData} />
              </div>
            </div>
          </div>
        </div>
        <div className="d-wrapper">
          <DownNavBar />
        </div>
      </div>
    </>
  );
};

export default Layout;
