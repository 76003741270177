import React, { useState } from "react";
import { LOGOWHITE } from "../../utlis/assests";
import { useNavigate } from "react-router-dom";
import "../style/form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faEye,
  faEyeSlash,
  faLock,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../context/DataContext";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { loginApi } from "../../config/DataApi";
const SignIn = () => {
  const navigate = useNavigate();
  const [auth]=useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const togglepasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = async (data) => {
    try {
      const responce = await loginApi(data);
      if (responce.status === 200) {
        console.log(responce.user);
        localStorage.setItem("token", responce.token);
        localStorage.setItem("email", responce.user.email);
        localStorage.setItem("userType", responce.user.type);
        (responce.user.type==1?navigate("/create-account"):navigate("/"))

      }else if(responce.status===404){
        alert("User not found");
        navigate('/sign-in');
    }else if(responce.status===401){
        alert("Invalid Credentials");
        navigate('/sign-in');
      }
    } catch (error) {
      console.log("error are in form sbmit function ", error);
    }
  };

  return (
    <>
      <div className="sign-in-page default-sin-in">
        <div className="sign-container">
          <div className="mid-align">
            <div className="left-content">
              <div className="logo-icon">
                <img src={LOGOWHITE} alt="Logo" />
              </div>
              <h1>
                <span className="white">Setup your</span> <br />
                <span className="grad-text">access</span>
              </h1>
              <div className="content-box">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Eu ut orci posuere id
                  egestas. Habitasse ut id lacus viverra vitae sagittis sit.
                </p>
              </div>
            </div>
            <div className="right-content">
              <div className="form-box">
                <form className="form-start" onSubmit={handleSubmit(onSubmit)}>
                  <h2>Login</h2>
                  <div className="input-box">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="addressmail@valid.com"
                      {...register("email", { required: "Email is required" })}
                    />
                    <span className="input-icon">
                      <FontAwesomeIcon icon={faLock} />
                      {/* <FontAwesomeIcon icon={faLockOpen} /> */}
                    </span>
                    {errors.email && (
                      <span className="error-message">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="input-box">
                    <label>Password</label>
                    <input
                      type={showPassword?"text": "password"}

                      {...register("password", {
                        required: "Password is required",
                      })}
                    />
                    <span className="input-icon" onClick={togglepasswordVisibility}>
                     {showPassword?<FontAwesomeIcon icon={faEyeSlash} />:<FontAwesomeIcon icon={faEye} />} 
                      {/*  */}
                    </span>
                    {errors.password && (
                      <span className="error-message">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                  <div className="link-box">
                    <Link to="/forgot-password">Forgotten password?</Link>
                  </div>
                  <div className="submit-box">
                    <input type="submit" value="Valider" />
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="form-footer-box">
            <div className="form-footer-box-inner">
              <div className="form-button-bottom">
                <FontAwesomeIcon icon={faArrowLeft} />
                Back
              </div>
              <div className="steps-counts">
                <span className="gold">step 01 on 13</span>
                <span className="hexa-icon s-active"></span>
                <span className="white">Setup your access</span>
                <div className="steps-list">
                  <span className="hexa-icon s-active"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                </div>
              </div>
              <div className="form-button-bottom b-active">
                Next
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SignIn;
