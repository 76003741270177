export const IMG_BASE_URL =  process.env.PUBLIC_URL;

export const LOGO = IMG_BASE_URL + "/assets/img/logo.svg";
export const UP_ARROW = IMG_BASE_URL + "/assets/img/nav-up.svg";
export const PORTFOLIO_ICON = IMG_BASE_URL + "/assets/img/portfolio.svg";
export const MARKET_ICON = IMG_BASE_URL + "/assets/img/market.svg";
export const FORECAST_ICON = IMG_BASE_URL + "/assets/img/forcast.svg";
export const PICKUP_ICON = IMG_BASE_URL + "/assets/img/pickup.svg";
export const ANALYTICS_ICON = IMG_BASE_URL + "/assets/img/analytics.svg";
export const OVERVIEW_ICON = IMG_BASE_URL + "/assets/img/overview.svg";
export const FILTER_BG = IMG_BASE_URL + "/assets/img/filter-bg.png";
export const CHECK_ICON = IMG_BASE_URL + "/assets/img/checkbox.svg";
export const LINK_ICON = IMG_BASE_URL + "/assets/img/link.svg";
export const GRAPH = IMG_BASE_URL + "/assets/img/graph.png";
export const CHART_IMG = IMG_BASE_URL + "/assets/img/chart-img.png";
export const SELECTED_DOT = IMG_BASE_URL + "/assets/img/selected.png";
export const STLY_DOT = IMG_BASE_URL + "/assets/img/stly.png";
export const FILTER = IMG_BASE_URL + "/assets/img/filter.svg";
export const OVERVIEWHOTEL= IMG_BASE_URL + "/assets/img/overview-hotel.svg";
export const OVERVIEWCALENDER= IMG_BASE_URL + "/assets/img/overview-calendar-search.svg";
export const OVERVIEWCALENDERRANGE= IMG_BASE_URL + "/assets/img/overview-calendar-range.svg";
export const OVERVIEWBARCHART= IMG_BASE_URL + "/assets/img/overview-bar-chart-horizontal-big.svg";
export const PROFILE_IMAGE= IMG_BASE_URL + "/assets/img/profile-img.svg";
export const CLOUD= IMG_BASE_URL + "/assets/img/side-icon-4.svg";
export const BELL2= IMG_BASE_URL + "/assets/img/bell-icon.svg";
export const INFO2= IMG_BASE_URL + "/assets/img/info-icon.svg";
export const SETTING2= IMG_BASE_URL + "/assets/img/settings-icon.svg";
export const SLIDELEFTBTN= IMG_BASE_URL + "/assets/img/slide-left-btn.svg";
export const LOGOSMALL = IMG_BASE_URL + "/assets/img/logo-small.svg";
export const LOGOWHITE = IMG_BASE_URL + "/assets/img/logo-white.svg";
export const LOGOSMALLBL = IMG_BASE_URL + "/assets/img/black-small-logo.svg";
export const USERROUND = IMG_BASE_URL + "/assets/img/user-round.svg";
export const SMALLPROFILE = IMG_BASE_URL + "/assets/img/small-profile-img.svg";
export const SMALLPROFILE1 = IMG_BASE_URL + "/assets/img/small-profile-img-1.svg";
export const HOTELIMG = IMG_BASE_URL + "/assets/img/hotel-img.svg";


