import React, { useState, useEffect } from "react";
import "./StickeyNevbartop.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../context/DataContext";
import { faInfo,faSolid,faArrowRight } from "@fortawesome/free-solid-svg-icons";
const StickeyNevbartop = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [managed,setManaged]=useState(1)
  const[auth]=useAuth();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // setManaged(auth)
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // console.log("managed data", managed);
    if (!auth) {
      setManaged(1);
    } else if (auth === 2 || auth === 3 ||  auth===4|| auth===1) {
    //   console.log(auth, "--", managed);
      setManaged(auth);
    }
  }, [auth]);
  return (
    <div className={`data-top-header ${isSticky ? "fixed" : ""}`}>
      <div className="data-inside">
        <div className={`data-box ${managed===1?"active":""}`}>
          <span className="safiro">Room Revenue</span>{" "}
          {/* <i className="fa-solid fa-arrow-right" />{" "} */}
          <FontAwesomeIcon icon={faArrowRight}/>
          <span className="sfiziare">$4.0M</span>{" "}
          <span className="green">+ 100</span>
        </div>
        <div className={`data-box ${managed==2?"active":""}`}>
          <span className="safiro">ADR</span>{" "}
          <span className="sfiziare">$4.0M</span>{" "}
          <span className="green">+ 100</span>
        </div>
        <div className={`data-box ${managed==3?"active":""}`}>
          <span className="safiro">Occupancy Rates</span>{" "}
          <span className="sfiziare">$4.0M</span>{" "}
          <span className="green">+ 100</span>
        </div>
        <div className={`data-box ${managed==4?"active":""}`}>
          <span className="safiro">RevPAR</span>{" "}
          <span className="sfiziare">$4.0M</span>{" "}
          <span className="green">+ 100</span>
        </div>
      </div>
    </div>
  );
};

export default StickeyNevbartop;
