import React from "react";
import { LOGOWHITE } from '../../utlis/assests';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faEye,
    faEyeSlash,
    faLock,
    faLockOpen,
} from "@fortawesome/free-solid-svg-icons";
import { Link,useNavigate } from "react-router-dom";
// import {useForm} from "react-hook-form";
import {useAuth}from "../../context/DataContext";
import {forgot} from "../../config/DataApi"
import { useForm } from "react-hook-form";
import { Alarm, Password } from "@mui/icons-material";
const Forgot  =()=>{
    const [auth]=useAuth();
    const navigate=useNavigate();
    const {register,handleSubmit,formState:{errors}}=useForm();
   const onSubmit= async (data)=>{
    try {
        const responce=await forgot(data);
        if(responce.status===200){
            console.log("code page are on progress ")
            alert("code prage are work on progress");
        }else if(responce.status===404){
            console.log("this email is not found in db")
      alert("this email is not found in db");
      navigate('/sign-in');
        }else if(responce.status===401){
            console.log("this email is not found in db")
            alert("api work progress");
        }
    } catch (error) {
        console.log("error are in onsubmit function of forgot PAss : ",error);
    }
   }
    return(
    <>
    <div className="sign-in-page default-sin-in">
        <div className="sign-container">
            <div className="mid-align">
                <div className="left-content">
                    <div className="logo-icon">
                        <img src={LOGOWHITE} alt="Logo" />
                    </div>
                    <h1><span className="white">Setup your</span> <br /><span className="grad-text">access</span></h1>
                    <div className="content-box">
                        <p>Lorem ipsum dolor sit amet consectetur. Eu ut orci posuere id egestas. Habitasse ut id lacus viverra vitae sagittis sit.</p>
                    </div>
                </div>
                <div className="right-content">

                    <div className="form-box">
                        <form className="form-start" onSubmit={handleSubmit(onSubmit)}>
                            <h2>Forgot Password</h2>
                            <Link to="/sign-in" className="back-link"> <FontAwesomeIcon icon={faArrowLeft} /> Retour</Link>
                            <div className="input-box with-text">
                                <label>Forgotten password?</label>
                                <p>Entrez votre adresse mail pour recevoir votre code de connexion.</p>
                                <input type="email" placeholder="adressmail@validee.com"
                                {...register("email",{required:"Email is required for forgot"})}
                                />
                                <span className="input-icon">
                                    <FontAwesomeIcon icon={faLock} />
                                    {/* <FontAwesomeIcon icon={faLockOpen} /> */}
                                </span>
                                {errors.email && (
                      <span className="error-message">
                        {errors.email.message}
                      </span>
                    )}
                            </div>
                            <div className="submit-box">
                                <input type="submit" value="Envoyer" />
                            </div>
                        </form>
                    </div>

                </div>
            </div>


            <div className="form-footer-box">

                <div className="form-footer-box-inner">
                    <div className="form-button-bottom">
                        <FontAwesomeIcon icon={faArrowLeft} />
                        Back
                    </div>

                    <div className="steps-counts">
                        <span className="gold">step 01 on 13</span>
                        <span class="hexa-icon s-active"></span>
                        <span className="white">
                            Setup your access
                        </span>
                        <div className="steps-list">
                            <span class="hexa-icon s-active"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                            <span class="hexa-icon"></span>
                        </div>
                    </div>

                    <div className="form-button-bottom b-active">
                        Next
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                </div>
            </div>


        </div>
    </div>
    </>
    )
}
export default Forgot;