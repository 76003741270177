import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CustomCalendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faFilter,
  faChevronRight,
  faChevronLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";
import RangeInputCalendar from "./RangeInput";
import RangeCustomSelect from "./RangeCustomSelect";

const CustomCalendar = ({ isActive, closePopup, ChildToParentPeriod }) => {
  const today = new Date();
  const year = today.getFullYear();
  const quarters = [
    { quarter: 1, start: new Date(year, 0, 1), end: new Date(year, 2, 31) },
    { quarter: 2, start: new Date(year, 3, 1), end: new Date(year, 5, 30) },
    { quarter: 3, start: new Date(year, 6, 1), end: new Date(year, 8, 30) },
    { quarter: 4, start: new Date(year, 9, 1), end: new Date(year, 11, 31) }
  ];
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, 1);
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  const lastDayOfMonth = new Date(nextMonth - 1);
  const [dateRange, setDateRange] = useState([firstDayOfMonth, lastDayOfMonth]);
  const [startingDate, setStartingDate] = useState(firstDayOfMonth);
  const [endingDate, setEndingDate] = useState(today);
  const [selectFilter, setSelectFilter] = useState("thisMonth");
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isDateRangeInitialized, setIsDateRangeInitialized] = useState(false);
  const [filterBartag, setFilterBartag] = useState(1);
  const[startingInputValue,setStartingInputValue]=useState();
  const[endingInputVlaue,setEndingInputValue]=useState();

  function getQuarter(date) {
    const month = date.getMonth();
    return Math.floor(month / 3) + 1;
  }

  const childToParent = (date) => {
    console.log("function called this ******^^^^^^^^^******!!!!!!!!@@@@@@#####*******^^^^^^^^^^^",date)
    // handleToggle4()
    setIsActive4(true)
    setSelectFilter("customValue");
    setFilterBartag(8);
  };

  const CustomFunction = (dates) => {
    console.log("function changed on date************** ", dates);
    setIsActive4(!isActive4);
    setSelectFilter("customValue");
    setFilterBartag(8);
    // setDateRange([dates, ""]);
  };

  const handleThisMonth = () => {
    setSelectFilter("thisMonth");
    setDateRange([firstDayOfMonth, today]);
    setFilterBartag(1);
    ChildToParentPeriod(dateRange);
  };

  const handleThisQuarter = () => {
    setSelectFilter("quarter");
    const currentQuarter = getQuarter(today);
    quarters.forEach((element) => {
      if (element.quarter === currentQuarter) {
        setDateRange([element.start, element.end]);
      }
    });
    setFilterBartag(2);
    ChildToParentPeriod(dateRange);
  };

  const handleThisYear = () => {
    setSelectFilter("year");
    setDateRange([firstDayOfYear, new Date(today.getFullYear(), 11, 31)]);
    setFilterBartag(3);
    ChildToParentPeriod(dateRange);
  };

  const handleMonthToDate = () => {
    setSelectFilter("monthToDate");
    setDateRange([firstDayOfMonth, today]);
    setFilterBartag(4);
    ChildToParentPeriod(dateRange);
  };

  const handleYearToDate = () => {
    setSelectFilter("yearToDate");
    setDateRange([firstDayOfYear, today]);
    setFilterBartag(5);
    ChildToParentPeriod(dateRange);
  };

  const submitFunction = () => {
    ChildToParentPeriod(dateRange);
    closePopup();
  };

  const handleToggle1 = () => {
    setIsActive1(!isActive1);
  };

  const handleToggle2 = () => {
    setIsActive2(!isActive2);
  };

  const handleToggle3 = () => {
    setIsActive3(!isActive3);
  };

  const handleToggle4 = () => {
    setIsActive4(!isActive4);
    ChildToParentPeriod(dateRange);
    setSelectFilter("customValue");
    setFilterBartag(8);
  };

  const handleDateChange = (setter, nu) => (event) => {
    try {
      const dateValue = event.target.value;
      console.log("value  on click : ",dateValue)
      if (!dateValue) {
        setter(null);
        return;
      }

      const parsedDate = new Date(dateValue);
      if (isNaN(parsedDate.getTime())) {
        throw new Error('Invalid Date');
      }

      if (nu === 1) {
        setStartingInputValue(parsedDate);
        setStartingDate(parsedDate);
      }
      if (nu === 2) {
        setEndingInputValue(parsedDate);
        setEndingDate(parsedDate);
      }

      setter(parsedDate);

    } catch (error) {
      console.log("Invalid date selected");
    }
  };

  useEffect(() => {
    setDateRange([startingDate, endingDate]);
    if (startingDate && endingDate) {
      setIsDateRangeInitialized(true);
    }
  }, [startingDate, endingDate]);

  useEffect(() => {
    setDateRange([firstDayOfMonth, lastDayOfMonth]);
    setIsDateRangeInitialized(true);
  }, []);

  if (!isActive) return null;

  return (
    <div className="calendar-container">
      <div className="close-btn" onClick={closePopup}></div>

      <div className="filters">
        <div className="filter-title">
          <FontAwesomeIcon icon={faFilter} /> Filters
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="thisMonth">
              <label htmlFor="thisMonths" className="checkbox">
                This month
                <input
                  type="radio"
                  id="thisMonths"
                  name="filters"
                  checked={filterBartag === 1}
                  onChange={handleThisMonth}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="quarter">
              <label htmlFor="quarter" className="checkbox">
                This quarter
                <input
                  type="radio"
                  id="quarter"
                  name="filters"
                  checked={filterBartag === 2}
                  onChange={handleThisQuarter}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="year">
              <label htmlFor="year" className="checkbox">
                This year
                <input
                  type="radio"
                  id="year"
                  name="filters"
                  checked={filterBartag === 3}
                  onChange={handleThisYear}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="monthToDate">
              <label htmlFor="monthToDate" className="checkbox">
                Month to date
                <input
                  type="radio"
                  id="monthToDate"
                  name="filters"
                  checked={filterBartag === 4}
                  onChange={handleMonthToDate}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className="filter-group">
          <div className="sort-by-filter">
            <span className="option" value="yearToDate">
              <label htmlFor="yearToDate" className="checkbox">
                Year to date
                <input
                  type="radio"
                  id="yearToDate"
                  name="filters"
                  checked={filterBartag === 5}
                  onChange={handleYearToDate}
                />
                <span className="checkmark" />
              </label>
            </span>
          </div>
        </div>

        <div className={`toggle-box ${isActive1 ? "active" : ""}`}>
          <div className="title-box" onClick={handleToggle1}>
            <div className="filter-title">Events</div>
            <span className="toggle-btn">
              <FontAwesomeIcon icon={isActive1 ? faAngleUp : faAngleDown} />
            </span>
          </div>
          <div className="filter-group">
            <div className="sort-by-filter">
              <span className="option" value="Events">
                <label htmlFor="event1" className="checkbox">
                  Events
                  <input type="radio" id="event1" name="event" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>

            <div className="sort-by-filter">
              <span className="option" value="Events">
                <label htmlFor="event2" className="checkbox">
                  Events
                  <input type="radio" id="event2" name="event" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>
          </div>
        </div>

        <div className={`toggle-box ${isActive2 ? "active" : ""}`}>
          <div className="title-box" onClick={handleToggle2}>
            <div className="filter-title">Next</div>
            <span className="toggle-btn">
              <FontAwesomeIcon icon={isActive2 ? faAngleUp : faAngleDown} />
            </span>
          </div>
          <div className="filter-group">
            <div className="sort-by-filter">
              <span className="option" value="Next">
                <label htmlFor="next1" className="checkbox">
                  Next
                  <input type="radio" id="next1" name="next" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>

            <div className="sort-by-filter">
              <span className="option" value="Last">
                <label htmlFor="next2" className="checkbox">
                  Last
                  <input type="radio" id="next2" name="next" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>
          </div>
        </div>

        <div className={`toggle-box ${isActive3 ? "active" : ""}`}>
          <div className="title-box" onClick={handleToggle3}>
            <div className="filter-title">Last</div>
            <span className="toggle-btn">
              <FontAwesomeIcon icon={isActive3 ? faAngleUp : faAngleDown} />
            </span>
          </div>
          <div className="filter-group">
            <div className="sort-by-filter">
              <span className="option" value="Last">
                <label htmlFor="last1" className="checkbox">
                  Last
                  <input type="radio" id="last1" name="last" />
                  <span className="checkmark" />
                </label>
              </span>

              <span className="option" value="Last">
                <label htmlFor="last2" className="checkbox">
                  Last
                  <input type="radio" id="last2" name="last" />
                  <span className="checkmark" />
                </label>
              </span>
            </div>
          </div>
        </div>

        <div className={`toggle-box ${isActive4 ? "active" : ""}`}>
          <div className="title-box" onClick={handleToggle4}>
            <div className="filter-title">Custom range</div>
            <span className="toggle-btn">
              <FontAwesomeIcon icon={isActive4 ? faAngleUp : faAngleDown} />
            </span>
          </div>
          <div className="filter-group">
            <div className="sort-by-filter input-date">
              <input
                type="date"
                value={startingInputValue}
                onChange={handleDateChange(setStartingDate, 1)}
              />{" "}
              <span className="mid-txt">to</span>
              <input
                type="date"
                value={endingInputVlaue}
                onChange={handleDateChange(setEndingDate, 2)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="calendar-box">
        {selectFilter === "quarter" && (
          <>
            <RangeCustomSelect childToParent={childToParent} dateRanged={dateRange} />
          </>
        )}
        {selectFilter === "thisMonth" && isDateRangeInitialized && (
          <>
            <RangeInputCalendar childToParent={childToParent} dateRanged={dateRange} />
          </>
        )}
        {selectFilter === "year" && (
          <>
            <Calendar
              onChange={CustomFunction}
              value={dateRange}
              className="custom-calendar"
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            />
          </>
        )}
        {selectFilter === "monthToDate" && (
          <>
            <Calendar
              onChange={CustomFunction}
              value={dateRange}
              className="custom-calendar"
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            />
          </>
        )}
        {selectFilter === "yearToDate" && (
          <>
            <Calendar
              onChange={CustomFunction}
              value={dateRange}
              className="custom-calendar"
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            />
          </>
        )}
        {selectFilter === "customValue" && (
          <>  
            <Calendar
              selectRange={true}
              onChange={setDateRange}
              value={dateRange}
              className="custom-calendar"
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            />
          </>
        )}

        <div className="calendar-footer">
          <button className="cancel-button" onClick={closePopup}>
            Cancel
          </button>
          <button className="apply-button" onClick={submitFunction}>Apply</button>
        </div>
      </div>
    </div>
  );
};

export default CustomCalendar;