import { Email, Password } from "@mui/icons-material";

export function isNegativeNumber(str) {
    // Convert the string to a number
    const num = parseFloat(str);
    
    // Check if the number is less than zero
    return num < 0;
  }

  export const countryData = [
    {
      id: 1,
      name: "France",
      thisyear: 1.9,
      lastyear: 1.3 ,
      // thisyear: "2.4 M",
      // lastyear: "2,4 M",
     
      
    },
    {
      id: 2,
      name: "Germany",
      thisyear: 2.6 ,
      lastyear: 2.8,
      // thisyear: "2.4 M",
      // lastyear: "2.4 M",
    },
    {
      id: 3,
      name: "USA",
      thisyear: 2.4 ,
      lastyear: 2.4 ,
      // thisyear: "2.4 M",
      // lastyear: "2.4 M",
    },
    
  ];

  export const days = [

    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },
  ];

 export  const hotels = [
    { value: 'Option1', label: 'Option 1' },
    { value: 'Option2', label: 'Option 2' },
    { value: 'Option3', label: 'Option 3' },
  ];
  export const countryDataChange = [
    {
      id: 1,
      name: "UK",
      thisyear: 2.9,
      lastyear: 4.3 ,
      // thisyear: "2.4 M",
      // lastyear: "2,4 M",
     
      
    },
    {
      id: 2,
      name: "IND",
      thisyear: 1.6 ,
      lastyear: 6.8,
      // thisyear: "2.4 M",
      // lastyear: "2.4 M",
    },
    {
      id: 3,
      name: "USA",
      thisyear: 3.4 ,
      lastyear: 5.4 ,
      // thisyear: "2.4 M",
      // lastyear: "2.4 M",
    },
    {
      id: 4,
      name: "UAE",
      thisyear: 6.4 ,
      lastyear: 8.4 ,
      // thisyear: "2.4 M",
      // lastyear: "2.4 M",
    },
    
  ];
  export const Users = [
    {
      type:1,//for admin
      name:"Bee Revenu",
      email:"admin@beerevenue.ai",
      userName:"admin@beerevenue.ai",
      password:"beerev1234",
    },
    {
      type:2,//for user
      name:"test",
      email:"test@gmail.com",
      userName:"test@gmail.com",
      password:"test@123",
    },
    {
      type:2,//for User
      name:"virat",
      email:"virat@gmail.com",
      userName:"virat@gmail.com",
      password:"virat@123",
    },
  ]
