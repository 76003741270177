import React, { useEffect, useState } from "react";

import {
  LOGOSMALLBL,
  LOGOWHITE,
  SMALLPROFILE,
  SMALLPROFILE1,
  USERROUND,
} from "../../utlis/assests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faArrowUpFromBracket,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const YourTeam = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isTask, setIsTask] = useState(false);
  const [selectRole, setSelectRole] = useState("Select Role");
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [editMemberIndex, setEditMemberIndex] = useState(null);
  const [deleteMemberIndex, setDeleteMemberIndex] = useState(null);
  const [profileImagText, setProfileImagText] = useState(
    "Upload profile picture"
  );
  const roleOptions = ["Viewer", "Super Admin", "Admin", "Manager"];
  const taskOptions = ["Task 1", "Task 2", "Task 3", "Task 4", "Task 5"];
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const toggleRoles = () => {
    setIsOpen(!isOpen);
    if (!isOpen) setIsTask(false);
  };

  const toggleTasks = () => {
    setIsTask(!isTask);
    if (!isTask) setIsOpen(false);
  };

  const handleRoleSelect = (role) => {
    setSelectRole(role);
    setIsOpen(false);
  };

  const navigateFunction = () => {
    navigate("/");
  };

  const handleTaskSelect = (task) => {
    setSelectedTasks((prevSelectedTasks) =>
      prevSelectedTasks.includes(task)
        ? prevSelectedTasks.filter((t) => t !== task)
        : [...prevSelectedTasks, task]
    );
    setIsTask(false);
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleDelete = (index) => {
    const member = userArray[index];
    setValue("firstName", member.firstName);
    setValue("lastName", member.lastName);
    setValue("email", member.email);
    setSelectRole(member.role);
    setSelectedTasks(member.tasks);
    setProfileImage(member.profileImage);
    setDeleteMemberIndex(index);
    setEditMemberIndex(null);
  };

  const handleEdit = (index) => {
    const member = userArray[index];
    setValue("firstName", member.firstName);
    setValue("lastName", member.lastName);
    setValue("email", member.email);
    setSelectRole(member.role);
    setSelectedTasks(member.tasks);
    setProfileImage(member.profileImage);
    setProfileImagText("Change  Profile ");
    setDeleteMemberIndex(null);
    setEditMemberIndex(index);
  };

  const onSubmit = (data) => {
    const formData = {
      ...data,
      role: selectRole,
      tasks: selectedTasks,
      profileImage: profileImage,
    };

    if (editMemberIndex !== null) {
      const updatedUserArray = [...userArray];
      updatedUserArray[editMemberIndex] = formData;
      setUserArray(updatedUserArray);
      localStorage.setItem("onboarding-team", JSON.stringify(updatedUserArray));
      setEditMemberIndex(null);
    } else if (deleteMemberIndex !== null) {
      const updatedUserArray = [...userArray];
      updatedUserArray.splice(deleteMemberIndex, 1);
      setUserArray(updatedUserArray);
      localStorage.setItem("onboarding-team", JSON.stringify(updatedUserArray));
      setDeleteMemberIndex(null);
    } else {
      localStorage.setItem(
        "onboarding-team",
        JSON.stringify([...userArray, formData])
      );
      setUserArray((prevUserArray) => [...prevUserArray, formData]);
    }

    reset(); //(Resert fields FOrm and imAgEs)
    setProfileImage(null);
    setSelectRole("Select Role");
    setSelectedTasks([]);
  };

  useEffect(() => {
    if (localStorage.getItem("onboarding-team")) {
      setUserArray(JSON.parse(localStorage.getItem("onboarding-team")));
    }
  }, []);

  return (
    <>
      <div className="sign-in-page default-sin-in">
        <div className="sign-container">
          <div className="mid-align">
            <div className="left-content">
              <div className="logo-icon">
                <img src={LOGOWHITE} alt="Logo" />
              </div>
              <h1>
                <span className="white">Your </span>{" "}
                <span className="grad-text">team</span>
              </h1>
              <div className="content-box">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Eu ut orci posuere id
                  egestas. Habitasse ut id lacus viverra vitae sagittis sit.
                </p>
              </div>

              <div className="members-box">
                <div className="title-box">
                  <h3 className="white">Current members</h3>
                  <div className="num gold">{userArray.length}</div>
                </div>

                <div className="members-list-box-outer">
                  {userArray.map((user, index) => (
                    <div className="members-list-box" key={index}>
                      <span className="members-inner user-box">
                        <img
                          src={user.profileImage || SMALLPROFILE1}
                          alt="Profile"
                        />
                        <span className="name">
                          {user.firstName} {user.lastName}
                        </span>
                      </span>
                      <span className="members-inner role-box">
                        <span className="role">{user.role}</span>
                      </span>
                      <span className="members-inner mail-box">
                        <span className="email">{user.email}</span>
                      </span>
                      <div className="icon-box-outer">
                        <span
                          className="icon-box"
                          onClick={() => handleEdit(index)}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </span>
                        <span
                          className="icon-box"
                          onClick={() => handleDelete(index)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="right-content">
              <div className="form-box">
                <form className="form-start" onSubmit={handleSubmit(onSubmit)}>
                  <div className="two-col">
                    <div className="left">
                      <h2>
                        {editMemberIndex !== null
                          ? "Save Modification"
                          : "Add a new member"}
                      </h2>
                      <div className="icon-text">
                        <div className="gold">
                          <input
                            type="file"
                            accept="image/*"
                            id="toggle-input"
                            onChange={handleProfilePictureChange}
                          />
                          <label htmlFor="toggle-input">
                            <div className="icon-box">
                              <FontAwesomeIcon icon={faArrowUpFromBracket} />
                            </div>
                            {profileImagText}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <div className="form-user">
                        <img
                          src={profileImage || USERROUND}
                          alt="Profile Preview"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="input-box">
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="Enter your First Name"
                      {...register("firstName", {
                        required: "First name is required",
                      })}
                    />
                    {errors.firstName && (
                      <span className="error-message">
                        {errors.firstName.message}
                      </span>
                    )}
                  </div>
                  <div className="input-box">
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Enter your Surname"
                      {...register("lastName", {
                        required: "Last name is required",
                      })}
                    />
                    {errors.lastName && (
                      <span className="error-message">
                        {errors.lastName.message}
                      </span>
                    )}
                  </div>
                  <div className="input-box">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Enter your Email"
                      {...register("email", { required: "Email is required" })}
                    />
                    {errors.email && (
                      <span className="error-message">
                        {errors.email.message}
                      </span>
                    )}
                  </div>

                  <div className="form-select-box-outer">
                    <div className="form-select-box">
                      <div className="title-box" onClick={toggleRoles}>
                        <span className="selected-label">{selectRole}</span>
                        <div className="open-btn">
                          <FontAwesomeIcon
                            icon={isOpen ? faAngleUp : faAngleDown}
                          />
                        </div>
                      </div>
                      {isOpen && (
                        <div className="select-box-option open">
                          <ul>
                            {roleOptions.map((role, index) => (
                              <li
                                key={index}
                                onClick={() => handleRoleSelect(role)}
                              >
                                {role}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-select-box-outer">
                    <label>Optional</label>
                    <div className="form-select-box">
                      <div className="title-box" onClick={toggleTasks}>
                        <span className="selected-label">
                          {selectedTasks.length > 0
                            ? selectedTasks.join(", ")
                            : "Select Tasks"}
                        </span>
                        <div className="open-btn">
                          <FontAwesomeIcon
                            icon={isTask ? faAngleUp : faAngleDown}
                          />
                        </div>
                      </div>
                      {isTask && (
                        <div className="select-box-option open">
                          <ul>
                            {taskOptions.map((task, index) => (
                              <li
                                key={index}
                                onClick={() => handleTaskSelect(task)}
                                className={
                                  selectedTasks.includes(task) ? "selected" : ""
                                }
                              >
                                <label htmlFor={`task-${index}`}>{task}</label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="submit-box">
                    {editMemberIndex !== null && (
                      <>
                        <input type="submit" value="Edit member" />{" "}
                        <div className="link-bottom">
                          <Link className="delete disabled">
                            <FontAwesomeIcon icon={faTrash} /> Delete this
                            Member
                          </Link>
                        </div>
                      </>
                    )}
                    {deleteMemberIndex !== null && (
                      <>
                        <input
                          className="disabled"
                          value="Save modifications"
                        />
                        <div className="link-bottom">
                          <label for="submit-user" className="delete-member">
                            <input
                              type="submit"
                              id="submit-user"
                              className="delete"
                            />
                            <FontAwesomeIcon icon={faTrash} /> Delete this user{" "}
                          </label>
                        </div>
                      </>
                    )}
                    {editMemberIndex === null && deleteMemberIndex === null && (
                      <>
                        {" "}
                        <input type="submit" value="Add member" />{" "}
                        <input type="button" value="Valider" />{" "}
                        <div className="link-bottom">
                          <Link to="/" className="skip">
                            Skip this step{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="form-footer-box">
            <div className="form-footer-box-inner">
              <div className="form-button-bottom">
                <FontAwesomeIcon icon={faArrowLeft} />
                Back
              </div>

              <div className="steps-counts">
                <span className="gold">step 01 on 13</span>
                <span className="hexa-icon s-active"></span>
                <span className="white">Setup your access</span>
                <div className="steps-list">
                  <span className="hexa-icon s-active"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                  <span className="hexa-icon"></span>
                </div>
              </div>

              <div className="form-button-bottom b-active">
                Next
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourTeam;
