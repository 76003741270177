import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import  borderRadius from "highcharts-border-radius";
import "../cards/chart.css";
borderRadius(Highcharts);

const SmallChart = ({ className, dataArray }) => {
    const [shownPopUP, setShownPopUP] = useState(false);
    const [active,setActive]=useState("1")
    const handleSetActive = (value) => {
      setActive(value);
    };
    const options = {
      chart: {
        type: "column",
        height:250,
        title: {
          text: "List Production Data",
          align: "left",
          visible: false,
        },
        subtitle: {
          text: 'Source: <a href="https://www.worlddata.info/average-bodyheight.php" target="_blank">WorldData</a>',
          align: "left",
          visible: false,
        },
      },
      yAxis: {
        visible: false,
      },
      xAxis: {
        categories: dataArray.map((item) => item.name),
        accessibility: {
          description: "Countries",
        },
        labels: {
          enabled: true,
        },
        lineColor: "transparent",
        lineWidth: 0,
      },
      tooltip: {
        valueSuffix: "1000 MT)",
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0.8,
          pointWidth: 40,
          borderRadius: 40,
          borderRadiusBottom:20,
        },
      },
      plotOptions: {
        series: {
          events: {
            click: function (event) {
              console.log(
                "function is called point shown: ",
                event.point.category,
                event.point.lastyear,
                event.point.thisyear
              );
              setShownPopUP(true);
            },
          },
          borderRadiusTopLeft: 20,
          borderRadiusTopRight: 20,
          borderRadiusBottomRight: 20,
          borderRadiusBottomLeft: 20
        },
      },
      series: [
          {
            name: "This Year",
            color: "#f4bf7d",
            showInLegend: false,
            data: dataArray.map((item) => ({
              y: parseFloat(item.thisyear),
              color: "#f4bf7d",
              
            })),
            className: "corn",
          
          },
          {
            name: "Last Year",
            showInLegend: false,
            data: dataArray.map((item) => ({
              y: parseFloat(item.lastyear),
              color: "#f2e5d4",
            })),
            className: "wheat",
            color: "#f2e5d4",
            
          },
      ],
    };

    return (
      <div className={className}>{shownPopUP&&(           
          <>
        <div className="breakdown-pop-up-outer">
          <div className="breakdown-pop-up">
            <div className="container-breakdown">
              <div className="month-top-head">
                <ul>
                  <li className={` ${active=="1"?"active":""}`} onClick={()=> handleSetActive("1")}>January 2023</li>
                  <li  className={` ${active=="2"?"active":""}`} onClick={()=> handleSetActive("2")}>February 2023</li>
                  <li className={` ${active=="3"?"active":""}`} onClick={()=> handleSetActive("3")}>March 2023</li>
                  <li className={` ${active=="4"?"active":""}`} onClick={()=> handleSetActive("4")}>April 2023</li>
                  <li className={` ${active=="5"?"active":""}`} onClick={()=> handleSetActive("5")}>May 2023</li>
                </ul>
                <div className="close-btn" onClick={(e) => setShownPopUP(false)}>
                  {/* <img src="images/close-btn.png" alt="btn" /> */}
                </div>
              </div>
              <div className="breakdown-table">
                <table width="100%" cellSpacing={0} cellPadding={0}>
                  <tbody>
                    <tr>
                      <th />
                      <th>
                        Occupancy / <br />
                        Room nights
                      </th>
                      <th>ADR (€)</th>
                      <th>Revenue (€)</th>
                      <th>RevPAR</th>
                    </tr>
                    <tr>
                      <td>
                        This year <br />
                        Pick-up 14 days
                      </td>
                      <td>
                        % 3.9 / 85 <br />
                        <span className="green">+ 8,2pt</span> /{" "}
                        <span className="green">+9</span>
                      </td>
                      <td>
                        € 230,6 <br />
                        <span className="green">+ 0,4</span> / 234,0
                      </td>
                      <td>
                        € 19 601 <br />
                        <span className="green">+ 2 106</span>
                      </td>
                      <td>
                        178,0 <br />
                        <span className="green">+ 19</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        This year <br />
                        Pick-up 14 days
                      </td>
                      <td>
                        % 67,3 / 74
                        <br />
                        <span className="green">+ 8,2pt</span> /{" "}
                        <span className="red">- 5</span>
                      </td>
                      <td>
                        € 218,4
                        <br />
                        <span className="green">+ 0,5</span> / 210,2
                      </td>
                      <td>
                        € 16 162
                        <br />
                        <span className="red">- 1 051</span>
                      </td>
                      <td>
                        146,0
                        <br />
                        <span className="red">- 9,6</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Variation <br />
                        Pick-up variations
                      </td>
                      <td>
                        <span className="green">+ 9,9pt</span> /{" "}
                        <span className="green">+ 11</span>
                        <br />
                        <span className="green">+ 13,3pt</span> /{" "}
                        <span className="green">+ 14</span>
                      </td>
                      <td>
                        <span className="green">+ 12,2</span>
                        <br />
                        <span className="green">+ 23,8</span>
                      </td>
                      <td>
                        <span className="green">+ 3 439</span>
                        <br />
                        <span className="green">+ 3 157</span>
                      </td>
                      <td>
                        <span className="green">+ 31,1</span>
                        <br />
                        <span className="green">+ 28,6</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
</>
)}
        <HighchartsReact highcharts={Highcharts} options={options}  />
      </div>
    );
  };
  export default SmallChart;


// import React, { useState } from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
// import  borderRadius from "highcharts-border-radius";
// import "../cards/chart.css";
// borderRadius(Highcharts);

// const SmallChart = ({ className, dataArray }) => {
//     const [shownPopUP, setShownPopUP] = useState(false);

//     const options = {
//       chart: {
//         type: "column",
//         height:250,
//         title: {
//           text: "List Production Data",
//           align: "left",
//           visible: false,
//         },
//         subtitle: {
//           text: 'Source: <a href="https://www.worlddata.info/average-bodyheight.php" target="_blank">WorldData</a>',
//           align: "left",
//           visible: false,
//         },
//       },
//       yAxis: {
//         visible: false,
//       },
//       xAxis: {
//         categories: dataArray.map((item) => item.name),
//         accessibility: {
//           description: "Countries",
//         },
//         labels: {
//           enabled: true,
//         },
//         lineColor: "transparent",
//         lineWidth: 0,
//       },
//       tooltip: {
//         valueSuffix: "1000 MT)",
//       },
//       plotOptions: {
//         column: {
//           pointPadding: 0.2,
//           borderWidth: 0.8,
//           pointWidth: 20,
//           borderRadius: 20,
//           borderRadiusBottom:20,
//         },
//       },
//       plotOptions: {
//         series: {
//           events: {
//             click: function (event) {
//               console.log(
//                 "function is called point shown: ",
//                 event.point.category,
//                 event.point.lastyear,
//                 event.point.thisyear
//               );
//               setShownPopUP(true);
//             },
//           },
//           borderRadiusTopLeft: 20,
//           borderRadiusTopRight: 20,
//           borderRadiusBottomRight: 20,
//           borderRadiusBottomLeft: 20
//         },
//       },
//       series: [
//           {
//             name: "This Year",
//             color: "#f4bf7d",
//             showInLegend: false,
//             data: dataArray.map((item) => ({
//               y: parseFloat(item.thisyear),
//               color: "#f4bf7d",
              
//             })),
//             className: "corn",
          
//           },
//           {
//             name: "Last Year",
//             showInLegend: false,
//             data: dataArray.map((item) => ({
//               y: parseFloat(item.lastyear),
//               color: "#f2e5d4",
//             })),
//             className: "wheat",
//             color: "#f2e5d4",
            
//           },
//       ],
//     };

//     return (
//       <div className={className}>
//         <HighchartsReact highcharts={Highcharts} options={options} />
//       </div>
//     );
//   };
//   export default SmallChart;