// import React, { useState, useEffect } from 'react';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faChevronRight,
//   faChevronLeft,
//   faAngleDoubleRight,
//   faAngleDoubleLeft,
// } from "@fortawesome/free-solid-svg-icons";

// const RangeInputCompair = ({ childToParent, dateRange }) => {
//   const today = new Date();

//   const [dateRang, setDateRang] = useState(dateRange);

//   useEffect(() => {
//     setDateRang(dateRange);
//   }, [dateRange]);

//   const handleDateChanges = (dates) => {
//     // setDateRang([dates,today]);
//     childToParent(dates);
//   };

//   const tileDisabled = ({ date }) => date.getTime() > today.getTime();

//   return (
//     <div className="range-input-calendar">
//       <Calendar
//         selectRange={true}
//         onChange={handleDateChanges}
//         value={dateRang}
//         className="custom-calendar"
//         nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
//         prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
//         next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
//         prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
//         tileDisabled={tileDisabled}
//       />
//     </div>
//   );
// };

// export default RangeInputCompair;



//////////////////////////////////////////

import React, { useState ,useEffect} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";

const RangeInputCompair = ({childToParent,dateRange  }) => {
  const today = new Date();
  // const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  // const [dateRange, setDateRange] = useState([firstDayOfMonth, today]);
  const [dateRang, setDateRang] = useState([]);


  useEffect(() => {
    setDateRang(dateRange);
  } ,[dateRange]);

  const [range, setRange] = useState(dateRang);
  

  const handleDateChanges = (dates) => {

      childToParent(dates);
  
  };

  const tileDisabled = ({ date }) => date.getTime() > today.getTime();
  return (
    <div className="range-input-calendar">
      <Calendar

        onChange={handleDateChanges}
        value={dateRang}
        className="custom-calendar"
        nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
        next2Label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
        prev2Label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
      />
    </div>
  );
};

export default RangeInputCompair;