import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { MetricData } from "../../utlis/metric";
import classes from "./card.module.css";
import "./MetricCard.css";
import { useAuth } from "../../context/DataContext";
const MetricCard = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [active, setActive] = useState(1);
  const[toggle, setToggle]=useState(false);
const [data,setData]=useAuth();
  const handleSetActive = (value) => {
    setActive(value);
    setData(value)
  };
const  functionPercentage=()=>{
if(toggle==false){
  setToggle(true)
}else{
  setToggle(false)
}
}
  return (
    <div className="four-column-box">
      {MetricData.map((ma) => (
        <div className="col-box" key={ma.id} onClick={() => handleSetActive(ma.id)}>
          <div className={`col-box-inner font-16 ${active === ma.id ? "active" : ""}`}>
            <div className="box-top-outer">
              <div className="dis-flex box-top">
                <span>
                  <span className="hexa-icon" />
                  <span className="font-17 margin-l-5">{ma.title}</span>
                </span>
                <div className="icon">
                  <FontAwesomeIcon icon={faInfo} />
                  <div className="info-show">
                    Shot Details
                    <span className="arrow" />
                  </div>
                </div>
              </div>
              {ma.id === 3 && (
                <div className="checkbox-box">
                  <input className="tgl tgl-light" id="cb1-6" type="checkbox" onChange={functionPercentage} />
                  <label className="tgl-btn" htmlFor="cb1-6"></label>
                  Literal values
                </div>
              )}
            </div>
            <div className="number-box">
             {ma.id !==3  && ( <div className="dollar">
                $<span className="big-font">{ma.value}</span>{" "}
                <span className="right">M</span>
              </div>)}
             {ma.id===3&&toggle  && ( <div className="dollar">
                %<span className="big-font">340</span>{" "}
                <span className="right">M</span>
              </div>)}
             {ma.id===3&& !toggle  && ( <div className="dollar">
              $<span className="big-font">{ma.value}</span>{" "}
                <span className="right">M</span>
              </div>)}
              <div className="green font-25"> {ma.total}</div>
            </div>
            <table width="100%" cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td>Last year</td>
                  <td>{ma.lastYear}</td>
                  <td className="green">{ma.total}K</td>
                  <td>{ma.profit}</td>
                </tr>
                <tr>
                  <td>Budget</td>
                  <td>{ma.budget}</td>
                  <td>
                    <span className="red">-100K</span>
                  </td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td>Forecast</td>
                  <td>3.9 M</td>
                  <td className="green">+100K</td>
                  <td>30%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MetricCard;
