import React, { useState, useEffect } from "react";
import { LOGOWHITE } from '../../utlis/assests';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faEye,
    faEyeSlash,
    faLock,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const CreateAcc = () => {
    const navigate=useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassIcon, setConfirmPassIcon] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [passwordsMatch, setPasswordsMatch] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfPasswordVisibility = () => {
        setConfirmPassIcon(!confirmPassIcon);
    };

    const onSubmit = async (data) => {
        localStorage.setItem("onboarding", JSON.stringify(data));
   navigate('/profile-setup');
    };

    const password = watch("password", "");
    const confirmPassword = watch("confirmPass", "");

    useEffect(() => {
        setPasswordsMatch(password === confirmPassword && password !== "");
    }, [password, confirmPassword]);

    return (
        <>
            <div className="sign-in-page default-sin-in">
                <div className="sign-container">
                    <div className="mid-align">
                        <div className="left-content">
                            <div className="logo-icon">
                                <img src={LOGOWHITE} alt="Logo" />
                            </div>
                            <h1><span className="white">Setup your</span> <br /><span className="grad-text">access</span></h1>
                            <div className="content-box">
                                <p>Lorem ipsum dolor sit amet consectetur. Eu ut orci posuere id egestas. Habitasse ut id lacus viverra vitae sagittis sit.</p>
                            </div>
                        </div>
                        <div className="right-content">
                            <div className="form-box">
                                <form className="form-start" onSubmit={handleSubmit(onSubmit)}>
                                    <h2>Create Account</h2>
                                    <div className="input-box">
                                        <label>Email</label>
                                        <input 
                                            type="email" 
                                            placeholder="addressmail@valid.com" 
                                            {...register("email", { required: "Email is required" })}
                                        />
                                        <span className="input-icon">
                                            <FontAwesomeIcon icon={faLock} />
                                        </span>
                                        {errors.email && <span className="error-message">{errors.email.message}</span>}
                                    </div>
                                    <div className="input-box">
                                        <label>Password</label>
                                        <input 
                                            type={showPassword ? "text" : "password"}
                                            {...register("password", { required: "Password is required" })}
                                        />
                                        <span className="input-icon" onClick={togglePasswordVisibility}>
                                            {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />} 
                                        </span>
                                        {errors.password && <span className="error-message">{errors.password.message}</span>}
                                    </div>
                            <ul className="sign-in-list">
                                <li>8 or more characters</li>
                                <li>Uppercase letters (A-Z)</li>
                                <li className="active">Lowercase letters (a-z)</li>
                                <li>Numbers (0-9)</li>
                                <li>Special character ($*+/%…)</li>
                                <li>Confirm password</li>
                                <li>********</li>
                                <li>Validé</li>
                                <li>Valider</li>
                            </ul>
                                    <div className="input-box">
                                        <label>Confirm Password</label>
                                        <input 
                                            type={confirmPassIcon ? "text" : "password"}
                                            {...register("confirmPass", { required: "Confirm Password is required" })}
                                        />
                                        <span className="input-icon" onClick={toggleConfPasswordVisibility}>
                                            {confirmPassIcon ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />} 
                                        </span>
                                        {errors.confirmPass && <span className="error-message">{errors.confirmPass.message}</span>}
                                    </div>
                                    <div className="submit-box">
                                        <input type="submit" value="Valider" disabled={!passwordsMatch} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="form-footer-box">
                        <div className="form-footer-box-inner">
                            <div className="form-button-bottom">
                                <FontAwesomeIcon icon={faArrowLeft} />
                                Back
                            </div>
                            <div className="steps-counts">
                                <span className="gold">step 01 on 13</span>
                                <span className="hexa-icon s-active"></span>
                                <span className="white">Setup your access</span>
                                <div className="steps-list">
                                    <span className="hexa-icon s-active"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                    <span className="hexa-icon"></span>
                                </div>
                            </div>
                            <div className="form-button-bottom b-active">
                                Next
                                <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateAcc;
