import React, { useState, useRef, useEffect } from "react";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HowerPopup from "./HowerPopup";
import ReactDOMServer from "react-dom/server";
import {
  faEye,
  faEyeSlash,
  faFilter,
  faMoon,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import "./newstyle.css";
import "./Highchart.css";
import { FILTER } from "./../../utlis/assests";

const ChartApplied = () => {
  // const htmlString = ReactDOMServer.renderToString(<MyTooltipComponent points={this.points} x={this.x} />);

  const [darkMode, setDarkMode] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [iconValues, setIconValues] = useState({});
  const [selectedValuee, setSelectedValuee] = useState("Days");
  const [selectedValue, setSelectedValue] = useState("Days");
  const [shownPopUP, setShownPopUP] = useState(false);
  const [weekends, setWeekends] = useState(false);
  const [active, setActive] = useState("1");
  const handleSetActive = (value) => {
    setActive(value);
  };
  const functionHandled = (val) => {
    console.log(val, "----");
    setSelectedValuee(val);
  };
  Highcharts.SVGRenderer.prototype.symbols.hexagon = (x, y, w, h) => {
    return [
      "M",
      x + w / 2,
      y,
      "L",
      x + w,
      y + h / 4,
      "L",
      x + w,
      y + (3 * h) / 4,
      "L",
      x + w / 2,
      y + h,
      "L",
      x,
      y + (3 * h) / 4,
      "L",
      x,
      y + h / 4,
      "Z",
    ];
  };

  Highcharts.SVGRenderer.prototype.symbols.customCylinder = function (
    x,
    y,
    w,
    h,
    options
  ) {
    const radius = options && options.radius ? options.radius : w / 4;
    const length = options && options.length ? options.length : h;
    const circleCount =
      options && options.circleCount ? options.circleCount : 4;

    // Calculate the coordinates for the cylinder
    const topCenterX = x + w / 2;
    const topCenterY = y;
    const circles = [];

    for (let i = 0; i < circleCount; i++) {
      const centerY = topCenterY + i * (length / (circleCount - 1));

      circles.push(
        "M",
        topCenterX - radius,
        centerY,
        "A",
        radius,
        radius / 2,
        0,
        1,
        0,
        topCenterX + radius,
        centerY,
        "A",
        radius,
        radius / 2,
        0,
        1,
        0,
        topCenterX - radius,
        centerY,
        "Z"
      );
    }

    return circles.concat([

      "M",
      topCenterX - radius,
      topCenterY,
      "L",
      topCenterX - radius,
      topCenterY + length,
      "L",
      topCenterX - radius,
      topCenterY + length + radius / 2,
      "L",
      topCenterX - radius,
      topCenterY + radius / 2,
      "Z",

      "M",
      topCenterX + radius,
      topCenterY,
      "L",
      topCenterX + radius,
      topCenterY + length,
      "L",
      topCenterX + radius,
      topCenterY + length + radius / 2,
      "L",
      topCenterX + radius,
      topCenterY + radius / 2,
      "Z",
    ]);
  };

  if (Highcharts.VMLRenderer) {
    Highcharts.VMLRenderer.prototype.symbols.customCylinder =
      Highcharts.SVGRenderer.prototype.symbols.customCylinder;
  }

  useEffect(() => {
    const handleSelectBoxClick = (event) => {
      event.stopPropagation();
      const currentBox = event.currentTarget;
      document
        .querySelectorAll(".sort-by-filter .select-box")
        .forEach((box) => {
          if (box !== currentBox) {
            box.classList.remove("toggle");
          }
        });
      currentBox.classList.toggle("toggle");
    };

    const handleOptionClick = (event) => {
      const selectedOption = event.target.innerText.trim();
      setSelectedValue(selectedOption);
    };

    const handleDocumentClick = (event) => {
      if (
        !event.target.closest(".select-box") &&
        !event.target.closest(".option-box")
      ) {
        document
          .querySelectorAll(".sort-by-filter .select-box")
          .forEach((selectBox) => {
            selectBox.classList.remove("toggle");
          });
      }
    };

    const selectBoxes = document.querySelectorAll(
      ".sort-by-filter .select-box"
    );
    selectBoxes.forEach((selectBox) => {
      selectBox.addEventListener("click", handleSelectBoxClick);
    });

    const optionBoxes = document.querySelectorAll(
      ".sort-by-filter .option-box .option"
    );
    optionBoxes.forEach((option) => {
      option.addEventListener("click", handleOptionClick);
    });

    document.addEventListener("click", handleDocumentClick);

    return () => {
      selectBoxes.forEach((selectBox) => {
        selectBox.removeEventListener("click", handleSelectBoxClick);
      });
      optionBoxes.forEach((option) => {
        option.removeEventListener("click", handleOptionClick);
      });
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
 
  
  const formatTooltip = () => {
    let s = `<div className="period-pop-up" style="background-color: #fff;font-family: strawfordregular; font-size:16px; font-weight:strong;
    border-radius: 15px;
    padding: 20px;
    z-index: 9;
    position: block;    
    box-shadow: 1px 1px 10px #9b9b9b;
    width: 260px;
    margin: auto;
}">
    <div className="dis-flex content-between data-box" style="display: flex; flex-wrap: wrap;border-bottom: 1px solid #f8f8f8;justify-content: space-between; padding: 10px 0 ;align-content: space-between;">
    <div className="head">January 2023</div>
    </div>
    <div className="dis-flex content-between data-box" style="border-bottom: 1px solid #f8f8f8;justify-content: space-between; padding: 10px 0;align-content: space-between;">
        <div className="left" style="float:left">
          <span className="black-circ circ" style="border-radius: 50px; display: inline-block;height: 12px;width: 12px;background-color: #0f0f0f;" />
        <p style="margin-left:20px;">Period<p>
        </div>
        <div className="right" style="float:right">$3,9M</div>
    </div>
    <div className="dis-flex content-between data-box" style="display: flex; flex-wrap: wrap;border-bottom: 1px solid #f8f8f8;justify-content: space-between; padding: 10px 0;align-content: space-between;">
      <div className="left" style="float:left">
        <span className="pink-circ circ" style="border-radius: 50px; display: inline-block;height: 12px;width: 12px;background-color: #f78757;" />
        <span className="green-circ circ" style="margin-left: 8px;border-radius: 50px; display: inline-block;height: 12px;width: 12px;background-color: #5bc3a3;" />
            <p style="margin-left:20px;">STYL</p>
      </div>
      <div className="right" style="float:right">$100K</div>
    </div>
    <div className="dis-flex content-between data-box" style="display: flex; flex-wrap: wrap;border-bottom: 1px solid #f8f8f8;justify-content: space-between; padding: 10px 0;align-content: space-between;">
    <div className="left" style="float:left">
      <span className="pink-circ circ" style="border-radius: 50px; display: inline-block;height: 12px;width: 12px;background-color: #d9a25d;" />
        <p style="margin-left:20px;">Pick-up</p>
    </div>
    <div className="right" style="float:right">$2.4M</div>
  </div>
  <div className="dis-flex content-between data-box" style="display: flex; flex-wrap: wrap;border-bottom: 1px solid #f8f8f8;justify-content: space-between; padding: 10px 0;align-content: space-between;">
    <div className="left" style="float:left">
     <span className="pink-circ circ" style="border-radius: 50px; display: inline-block;height: 12px;width: 12px;background-color: #d9a25d;" />
          <p style="margin-left:20px;">Budget</p>
    </div>
    <div className="right" style="float:right">$2.8M</div>
  </div>
  <div className="dis-flex content-between data-box" style="display: flex; flex-wrap: wrap;border-bottom: 1px solid #f8f8f8;justify-content: space-between; padding: 10px 0;align-content: space-between;">
    <div className="left" style="float:left">
      <span />
          <p ">--Forecast</p>
    </div>
    <div className="right" style="float:right">$2.9M</div>
  </div>
   
  </div>`;
    return s;
  };
  const customScrollbarCSS = `
  .highcharts-scrollbar .highcharts-scrollbar-button {
    background-color: ${darkMode ? "#666" : "#eee"};
    border-radius: 7px;
  }
  .highcharts-scrollbar .highcharts-scrollbar-button rect {
    fill: ${darkMode ? "#666" : "#eee"};
  }
  .highcharts-scrollbar .highcharts-scrollbar-arrow {
    fill: ${darkMode ? "#fff" : "#333"};
  }
`;
  const data = [
    {
      name: "Selected Period",
      type: "column",
      data: [84, 97, 48, 28, 85, 65, 23, 55, 63, 77, 84, 97],
      color: "Black",
      class: "black-circ",
      borderRadius: 20,
    },
    {
      name: "Week-end",
      type: "scatter",
      mode: "markers",
      marker: {
        symbol: "M -5.5 -5 L 5.5 -5 L 11 0 L 5.5 5 L -5.5 5 L -11 0 Z",
        fillColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        lineWidth: 2,
        radius: 5,
        height: 500,
      },
      color: "light-brown",
      class: "light-brown",
      // color: "#F3F3F3",
      data: [],
    },
    {
      name: "pick-up",
      type: "scatter",
      mode: "markers",
      marker: {
        symbol: "customCylinder",
        radius: 7,
        length: 9,
        circleCount: 19,
        fillColor: "#F78757",
      },

      // pointStyle: "hexagon",
      class: "green-pink-circ",
      data: [
        {
          x: 0.2,
          y: 90.44,
          marker: {
            fillColor: "#F78757",
            radius: 7,
            length: 9,
            circleCount: 7,
          },
        },
        {
          x: 1.2,
          y: 93.6,
          marker: {
            fillColor: "#5BC3A3",
            radius: 7,
            length: 37,
            circleCount: 57,
          },
        },
        // { x: 3, y: 84.7,  marker: {
        //   fillColor: '#ff7841'
        // } },
        { x: 4, y: 50.8, color: "#5BC3A3" },
        { x: 5, y: 70.8, color: "#F78757" },
        {
          x: 6.9,
          y: 55.8,
          marker: {
            fillColor: "#5BC3A3",
            radius: 7,
            length: 23,
            circleCount: 39,
          },
        },
        { x: 7, y: 90.8, color: "#5BC3A3" },
        { x: 8, y: 50.8, color: "#F78757" },
        {
          x: 11,
          y: 60.8,
          marker: {
            fillColor: "#F78757",
            circleCount: 11,
          },
        },
        // { x: 10, y: 70.8,  marker: {
        //   fillColor: 'green'
        // } },
        { x: 11, y: 60.8, color: "#5BC3A3" },
      ],
    },
    {
      name: "STYL",
      type: "line",
      data: [
        92.6, 51.5, 54.2, 45.8, 20.2, 15.4, 45.43, 78.54, 77.54, 78.56, 88.67,
        56.98,
      ],
      dashStyle: "ShortDash",
      color: "#f4bf7d",
      class: "gold-circ",
    },
    {
      name: "Budget",
      type: "scatter",
      data: [65, 67, 89, 32, 43, 54, 76, 87, 98],
      color: "#f4bf7d",
      marker: {
        symbol: "hexagon",
        radius: 6,
        fillColor: "#f4bf7d",
        lineWidth: 1,
        // lineColor: '#000000'
      },
      class: "brown-hex",
    },
    // {
    //   name:'area1',type:'area',data:[55,67,34,45,67,89,65,56,22,44,76,87],color:'#f4bf7d'
    // }
    {
      name: "Forecast",
      type: "area",
      data: [55, 67, 52, 70, 67, 89, 65, 56, 55, 64, 76, 87],
      color: "#f4bf7d",
      color: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, "#f4bf7d"],
          [1, "rgba(244,191,125,0)"],
        ],
      },
      pointStyle: "circle",
      pointRadius: 5,
      pointBorderColor: "#f4bf7d",
      pointBackgroundColor: "rgba(244,191,125,0)",
      class: "dash-line",
    },
  ];

    const options = {
      chart: {
        zoomType: "xy",
        backgroundColor: `${darkMode ? "#333" : " #fff"}`,
        // scrollablePlotArea: {
        //   minWidth: 1000,
        //   scrollPositionX: 1000 
        //   , height: 14,
        // },
        renderTo: 'container',
        defaultSeriesType: 'column',
      
      },
      title: {
        text: "Custom Chart",
        style: {
          fontFamily: "Arial, sans-serif",
          color: `${darkMode ? "#fff" : "#333 "}`, 
        },
      },
      credits: {
        enabled: false,
      position: {
            align: 'right',
            x: -10,
            verticalAlign: 'bottom',
            y: -5
        },
        href: "http://www.qualiantech.com",
        text: "Qualian Technologies Pvt Ltd"
    },
      xAxis: {
        categories: [
          "05/02",
          "06/02",
          "07/02",
          "01/02",
          "02/02",
          "03/02",
          "04/02",
          "05/02",
          "06/02",
          "07/02",
          "01/02",
          "02/02",
        ],
        labels: {
          useHTML: true,
          formatter: function () {
            const weekendDates = weekends ? ["01/02", "02/02"] : [];
            const date = this.value;

            if (weekendDates.includes(date)) {
              return `<b style="padding: 2px 20px; margin:0; background: #F2E5D4; border-radius: 26px; text-align: center; color: #0F0F0F; font-size: 10px;">${date}</b>`;
            }
            return `<b>${date}</b>`;
          },
          style: {
            fontFamily: "Arial, sans-serif",
            color: `${darkMode ? "#fff" : "#333 "}`, 
          },
        },
        // scrollbar: {
        //   enabled: true
          
        // },
    
      },
      yAxis: {
        title: {
          text: "",
          visible: false,
        },
        labels: {
          formatter: function () {
            return "<b style={{ marginLeft: '12px' }} >$ " + this.value + "M</b>";
          },
          style: {
            fontFamily: "Arial, sans-serif",
            color: `${darkMode ? "#fff" : "#333 "}`, // white text
          },
        },
      },
      tooltip: {
        formatter: function () {
          if (this.series.name === "Selected Period") {
            return formatTooltip.call(this);
          } else {
            return false;
          }
        },
        shared: false,
        useHTML: true,
        // style: { display :"none"}
        style: { opacity: 0 },
      },
      plotOptions: {
        series: {
          events: {
            click: function (event) {
              console.log("function is called : ", event.point.category, data);
              setShownPopUP(true);
              // alert('Clicked on ' + event.point.category);
            },
          },
        },
      },

      series: data,
      legend: {
        enabled: false,
      },
      scrollbar: {
        enabled:true,
  barBackgroundColor: 'gray',
  barBorderRadius: 7,
  barBorderWidth: 0,
  buttonBackgroundColor: 'gray',
  buttonBorderWidth: 0,
  buttonArrowColor: 'yellow',
  buttonBorderRadius: 7,
  rifleColor: 'yellow',
  trackBackgroundColor: 'white',
  trackBorderWidth: 1,
  trackBorderColor: 'silver',
  trackBorderRadius: 7
  }
    };

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleZoom = (direction) => {
    if (direction === "in") {
      setZoom(zoom + 0.1);
    } else {
      setZoom(zoom - 0.1);
    }
  };

  const toggleDatasetVisibility = (seriesIndex) => {
    if (seriesIndex == 1) {
      if (!weekends) {
        setWeekends(true);
      } else {
        setWeekends(false);
      }
    }
    const chart = chartRef.current.chart;
    const series = chart.series[seriesIndex];
    if (series.visible) {
      series.hide();
    } else {
      series.show();
    }
    setIconValues((prevIconValues) => ({
      ...prevIconValues,
      [seriesIndex]: !prevIconValues[seriesIndex],
    }));
  };

  const customLegend = () => {
    return (
      <div className={`high-chart-section ${darkMode ? "dark-mode" : ""}`}>
        <div className="left-block">
          <div className="dis-flex">
            <span className="font-17 safiro">Monthly breakdown</span>
            <div className="list">
              {data.map((dataset, index) => (
                <span
                  key={index}
                  style={{ cursor: "pointer" }}
                  className="font-13"
                  onClick={() => toggleDatasetVisibility(index)}
                >
                  <span className={`${dataset.class} circ`}></span>{" "}
                  {dataset.name}
                  {iconValues[index] ? (
                    <FontAwesomeIcon icon={faEyeSlash} className="m-1" />
                  ) : (
                    <i className="fa-regular fa-eye"></i>
                  )}
                </span>
              ))}
            </div>
          </div>
          <div className="sort-by-filter">
            <img src={FILTER} alt="" className="inline mr-1" />
            <span className="gold font-16"> View by </span>
            <div className="select-box ">
              <span>{selectedValuee}</span>
            </div>
            <div className="option-box">
              <span className="option" onClick={() => functionHandled("Days")}>
                <label className="checkbox" htmlFor="Day">
                  Days
                  <input
                    type="radio"
                    id="Day"
                    name="viewBy"
                    value="Days"
                    checked={selectedValuee === "Days"}
                    readOnly
                  />
                  <span className="checkmark"></span>
                </label>
              </span>
              <span className="option" onClick={() => functionHandled("month")}>
                <label className="checkbox" htmlFor="mo">
                  Month
                  <input
                    type="radio"
                    id="mo"
                    name="viewBy"
                    value="month"
                    checked={selectedValuee === "month"}
                    readOnly
                  />
                  <span className="checkmark"></span>
                </label>
              </span>
              <span className="option" onClick={() => functionHandled("Week")}>
                <label className="checkbox" htmlFor="week">
                  Week
                  <input
                    type="radio"
                    id="week"
                    name="viewBy"
                    value="Week"
                    checked={selectedValuee === "Week"}
                    readOnly
                  />
                  <span className="checkmark"></span>
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const chartRef = useRef(null);

  return (
    <>
      <div className={`first-chart-section`}>
        <div className={`chart-dark-mode ${darkMode ? "chart-container" : ""}`}>
          <div className="legendClass">{customLegend()}</div>
          <div className="chartWrapper">
            {shownPopUP && (
              <>
                <div className="breakdown-pop-up-outer">
                  <div className="breakdown-pop-up">
                    <div className="container-breakdown">
                      <div className="month-top-head">
                        <ul>
                          <li
                            className={` ${active == "1" ? "active" : ""}`}
                            onClick={() => handleSetActive("1")}
                          >
                            January 2023
                          </li>
                          <li
                            className={` ${active == "2" ? "active" : ""}`}
                            onClick={() => handleSetActive("2")}
                          >
                            February 2023
                          </li>
                          <li
                            className={` ${active == "3" ? "active" : ""}`}
                            onClick={() => handleSetActive("3")}
                          >
                            March 2023
                          </li>
                          <li
                            className={` ${active == "4" ? "active" : ""}`}
                            onClick={() => handleSetActive("4")}
                          >
                            April 2023
                          </li>
                          <li
                            className={` ${active == "5" ? "active" : ""}`}
                            onClick={() => handleSetActive("5")}
                          >
                            May 2023
                          </li>
                        </ul>
                        <div
                          className="close-btn"
                          onClick={(e) => setShownPopUP(false)}
                        >
                          {/* <img src="images/close-btn.png" alt="btn" /> */}
                        </div>
                      </div>
                      <div className="breakdown-table">
                        <table width="100%" cellSpacing={0} cellPadding={0}>
                          <tbody>
                            <tr>
                              <th />
                              <th>
                                Occupancy / <br />
                                Room nights
                              </th>
                              <th>ADR (€)</th>
                              <th>Revenue (€)</th>
                              <th>RevPAR</th>
                            </tr>
                            <tr>
                              <td>
                                This year <br />
                                Pick-up 14 days
                              </td>
                              <td>
                                % 3.9 / 85 <br />
                                <span className="green">+ 8,2pt</span> /{" "}
                                <span className="green">+9</span>
                              </td>
                              <td>
                                € 230,6 <br />
                                <span className="green">+ 0,4</span> / 234,0
                              </td>
                              <td>
                                € 19 601 <br />
                                <span className="green">+ 2 106</span>
                              </td>
                              <td>
                                178,0 <br />
                                <span className="green">+ 19</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                This year <br />
                                Pick-up 14 days
                              </td>
                              <td>
                                % 67,3 / 74
                                <br />
                                <span className="green">+ 8,2pt</span> /{" "}
                                <span className="red">- 5</span>
                              </td>
                              <td>
                                € 218,4
                                <br />
                                <span className="green">+ 0,5</span> / 210,2
                              </td>
                              <td>
                                € 16 162
                                <br />
                                <span className="red">- 1 051</span>
                              </td>
                              <td>
                                146,0
                                <br />
                                <span className="red">- 9,6</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Variation <br />
                                Pick-up variations
                              </td>
                              <td>
                                <span className="green">+ 9,9pt</span> /{" "}
                                <span className="green">+ 11</span>
                                <br />
                                <span className="green">+ 13,3pt</span> /{" "}
                                <span className="green">+ 14</span>
                              </td>
                              <td>
                                <span className="green">+ 12,2</span>
                                <br />
                                <span className="green">+ 23,8</span>
                              </td>
                              <td>
                                <span className="green">+ 3 439</span>
                                <br />
                                <span className="green">+ 3 157</span>
                              </td>
                              <td>
                                <span className="green">+ 31,1</span>
                                <br />
                                <span className="green">+ 28,6</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="chartAreaWrapper">
              <div
                className="chartAreaWrapper2"
                style={{ transform: `scale(${zoom})` }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                  ref={chartRef}
                />
              </div>
            </div>
          </div>
          <div className="zoom-in-out-box">
            <div className="zoom-in-out-inner">
              <div className="day-night" onClick={handleDarkMode}>
                <FontAwesomeIcon icon={faMoon} />
              </div>
              {/* <div className="slider">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  
                />
                <div className="slider-line">
                  <span />
                </div>
                <FontAwesomeIcon
                  icon={faAngleRight}
                
                /> 
              </div>*/}
              <div className="magnify">
                <div className="zoomin" onClick={() => handleZoom("in")}>
                  <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                </div>
                <div className="zoomout" onClick={() => handleZoom("out")}>
                  <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartApplied;